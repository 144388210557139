import { Outlet } from "react-router-dom";
import { Flex, Container } from "@telia/teddy";
import { HorizontalScroll, InformationItem, ToggleButtonGroup } from "@telia-no-min-side/components";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { InformationSectionMobile } from "./components/InformationSectionMobile";
import { MobileSubscriptionSuspended } from "../mobile-subscription-suspended";
import { DashboardTabs } from "./components/DashboardTabs";
import { getMobileSubscriptions } from "./utils/getMobileSubscriptions";
import { BillingType, SubscriptionStatus, UserRole } from "src/gql/graphql";
import { NoSubscriptionsFound } from "../../../components/profile-pickers/components/NoSubscriptionsFound";
import { getTabRoutes } from "./utils/getTabRoutes";
import { BuyNewSubscriptionCTA } from "src/components/profile-pickers/components/BuyNewSubscriptionCTA";
import { DashboardPicker } from "./components/DashboardPicker";
import { InformationSectionContainer } from "./style";
import { MobileTopMenu } from "components/top-menu/MobileTopMenu";
import { config } from "util/config";
import { TopMenuSkeleton } from "components/top-menu/TopMenuSkeleton";
import { MobileSubscriptionReserved } from "../mobile-subscription-reserved";
import { NotSupportedSubscription } from "pages/mobile/mobile-subscription-dashboard/modules";
import { PageContainer } from "components/PageContainer";

export function MobileSubscriptionDashboardLayout() {
  const { isLoading, allAccounts, phoneNumber } = useMobileAccount();
  if (isLoading) {
    return config.isNewProductPageEnabled ? (
      <TopMenuSkeleton />
    ) : (
      <>
        <Container>
          <HorizontalScroll addContainerScrollMargin>
            <Flex gap="200" maxWidth="full" data-tracking-id="profile-picker-loading">
              {[...Array(2)].map((_, i) => (
                <ToggleButtonGroup.Item.Skeleton variant="profile-picker" key={`toggle-button-skeleton-${i}`} />
              ))}
            </Flex>
          </HorizontalScroll>
        </Container>

        <InformationSectionContainer padding="vertical" flexDirection="row" flexWrap>
          {[...Array(3)].map((_, i) => (
            <InformationItem key={`information-section-skeleton-${i}`} isLoading fitContent title="" text="" />
          ))}
        </InformationSectionContainer>
      </>
    );
  }

  // TODO: when no accounts exists, show smth like "buy subscription"?
  if (!allAccounts) return null;

  const mobileSubscriptions = getMobileSubscriptions(allAccounts);
  const subscription = mobileSubscriptions.find((sub) => sub?.phoneNumber?.localNumber === phoneNumber);

  const subscriptionStatus = subscription?.subscriptionStatus;
  const subscriptionName = subscription?.userOffering?.shortName;
  const monthlyCost = subscription?.userOffering?.monthlyCost?.amount;
  const isPrepaid = subscription?.billingType === BillingType.Prepaid;

  const isSubscriptionReserved = subscriptionStatus === SubscriptionStatus.Reserved;
  const isSubscriptionSuspended = subscriptionStatus === SubscriptionStatus.Suspended && !!subscription?.productUser;

  const notSupportedSubscriptions = ["Telia Hjemme Mini"];
  const isNotSupportedSubscription = !!subscriptionName && notSupportedSubscriptions.includes(subscriptionName);

  const legalOwnerAccounts = allAccounts?.filter((account) => account.roles.includes(UserRole.LegalOwner));

  const tabs = getTabRoutes(isPrepaid, isNotSupportedSubscription, config.showNewPrepaidPage);

  if (mobileSubscriptions?.length === 0) return <NoSubscriptionsFound isMobile />;

  return (
    <>
      {config.isNewProductPageEnabled ? (
        <MobileTopMenu subscriptions={mobileSubscriptions || []} />
      ) : (
        <DashboardPicker subscriptions={mobileSubscriptions || []}>
          <BuyNewSubscriptionCTA legalOwnerAccounts={legalOwnerAccounts || []} />
        </DashboardPicker>
      )}

      <div id="tabpanel-for-picker" role="tabpanel" aria-labelledby={`tab-${phoneNumber}`} tabIndex={0}>
        {!config.isNewProductPageEnabled && <InformationSectionMobile />}

        {isSubscriptionSuspended && <MobileSubscriptionSuspended subscription={subscription} />}
        {isSubscriptionReserved && <MobileSubscriptionReserved />}
        {isNotSupportedSubscription && (
          <Flex p="400" pt="0">
            <NotSupportedSubscription name={subscriptionName} price={monthlyCost} />
          </Flex>
        )}

        {!isLoading && !isSubscriptionSuspended && !isSubscriptionReserved && !isNotSupportedSubscription && (
          <>
            {config.isNewProductPageEnabled ? (
              <>
                <PageContainer>
                  <Outlet context={{ isPrepaid }} />
                </PageContainer>
              </>
            ) : (
              <>
                <DashboardTabs tabs={tabs} />
                <PageContainer mt="400">
                  <Outlet context={{ isPrepaid }} />
                </PageContainer>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
