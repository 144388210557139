import { Flex, Chip, Notabene, Text } from "@telia/teddy";
import { Container, Heading, Skeleton } from "@telia-no-min-side/components";
import { Step1View } from "./views/Step1View";
import { Step2View } from "./views/Step2View";
import { useFlexOrder } from "../context/FlexOrderProvider";

export function ProductSelector() {
  const { isLoading, street, viewMode } = useFlexOrder();

  return (
    <Flex direction="column" gap="400" pb="1600">
      <Heading tag="h2" variant="title-300" removeMargin>
        Din Flex-avtale
      </Heading>

      {isLoading ? (
        <Skeleton variant="rectangular" height="4.5ch" width="20ch" />
      ) : street ? (
        <Chip variant="choice" type="single" defaultValue={street}>
          <Chip.Item value={street} data-di-mask>
            {street}
          </Chip.Item>
        </Chip>
      ) : null}

      <Container maxWidth="sm">
        <Notabene>
          <Notabene.Icon name="bulb" style={{ flexShrink: 0 }} />
          <Notabene.Content>
            <Notabene.Heading>Hva er Flex?</Notabene.Heading>
            <Text>
              Med Flex har du valgfriheten til å velge akkurat den internetthastigheten og antall TV-poeng som passer
              deg. Du kan bytte din Flex-pakke når du vil. Denne fleksible løsningen har du gjennom fellesavtalen for
              ditt borettslag/sameie.
            </Text>
          </Notabene.Content>
        </Notabene>
      </Container>

      {viewMode === "STEP1" && <Step1View />}
      {viewMode === "STEP2" && <Step2View />}
    </Flex>
  );
}
