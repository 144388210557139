import { Box, breakpoint } from "@telia/teddy";
import { useAuth } from "@telia-no-min-side/components";
import { useFetchBroadbandSubscription, useFetchSafeOnNet } from "src/api/fixed";
import { useFetchFlexCurrent } from "src/api/fixed";
import { AUTH } from "util/constants/auth";
import { SubscriptionInfoCard } from "./SubcriptionInfoCard";
import { FlexInfoCard } from "./FlexInfoCard";
import { showSafeOnNetBenefit } from "../utils/additionalServices";

export function SubscriptionInfo() {
  const { selectedAuthProvider } = useAuth();
  const broadbandSubscription = useFetchBroadbandSubscription();
  const broadbandSubscriptionProduct = broadbandSubscription.data?.products[0];
  const { safeOnNet } = useFetchSafeOnNet();
  const isSafeOnNetBenefitVisible = showSafeOnNetBenefit(safeOnNet);

  const flexCurrent = useFetchFlexCurrent();
  const showFlexCardOnlyIfCustomerHasBothTVandBB =
    flexCurrent.data?.currentTvAndBroadband?.broadbandOption && flexCurrent.data?.currentTvAndBroadband?.tvOption;

  if (selectedAuthProvider === AUTH.PROVIDER.CT) return null;

  if (!broadbandSubscription.data && broadbandSubscription.isError) return null;

  return (
    <Box maxWidth={breakpoint.teddyBreakpointMd} mb="400">
      {showFlexCardOnlyIfCustomerHasBothTVandBB ? (
        <FlexInfoCard
          flexCombo={flexCurrent.data}
          isLoading={flexCurrent.isValidating}
          actions={broadbandSubscriptionProduct?.actions}
          isSafeOnNetBenefitVisible={isSafeOnNetBenefitVisible}
        />
      ) : (
        <SubscriptionInfoCard
          subscriptionProduct={broadbandSubscriptionProduct}
          isLoading={broadbandSubscription.isLoading}
          isSafeOnNetBenefitVisible={isSafeOnNetBenefitVisible}
        />
      )}
    </Box>
  );
}
