import { Box, Heading, Text, Skeleton } from "@telia/teddy";
import { Breadcrumb } from "components/Breadcrumb";
import { Overview } from "./Overview";
import { ConnectedDevices } from "./ConnectedDevices";
import { WifiTips } from "./WifiTips";
import { useFetchNetworkStatistics } from "src/api/fixed";

export function WifiNetwork() {
  const { networkStatistics, isValidating } = useFetchNetworkStatistics();
  return (
    <>
      <Breadcrumb />
      <Box mt="200">
        {isValidating ? (
          <>
            <Heading as="h2" variant="title-300">
              Innstillinger
            </Heading>
            <Skeleton minHeight="100px" width="45%" mt="200" />
            <Skeleton minHeight="150px" width="50%" mt="200" />
            <WifiTips />
          </>
        ) : !networkStatistics?.success || networkStatistics?.result === "Customer Has No Lifemote Device" ? (
          <>
            <Heading as="h2" variant="title-300">
              Innstillinger
            </Heading>
            <Text mt="200">Beklager, du har ikke støtte for WiFi-nettverk.</Text>
            <WifiTips />
          </>
        ) : (
          <>
            <Overview networkStatistics={networkStatistics} />
            <ConnectedDevices />
            <WifiTips />
          </>
        )}
      </Box>
    </>
  );
}
