import { Outlet } from "react-router-dom";
import { Broadband } from "./pages/broadbandNew";
import { BroadbandDashboard as BroadbandDashboardNew } from "./pages/broadbandNew/dashboard";
import { WifiNetwork as WifiNetworkNew } from "./pages/broadbandNew/wifi-network";
import { BuyInternetEquipment as BuyInternetEquipmentNew } from "./pages/broadbandNew/buy-hardware";
import { PrepaidOrderHistory } from "./pages/prepaid/order-history";
import { MBBDashboard } from "pages/broadbandNew/dashboard/MBBDashboard";
import { AdditionalServicesBroadband } from "pages/broadbandNew/additional-service/AdditionalServicesBroadband";
import { AdditionalProductsProvider } from "hooks/useAdditionalProducts";
import { MobileSubscriptionSettings } from "pages/mobile/mobile-subscription-settings";
import { MobileSubscriptionDataUsageHistory } from "pages/mobile/mobile-subscription-data-usage-history";
import { MobileSubscriptionSimPinPuk } from "./pages/mobile/mobile-subscription-sim-pin-puk";
import { MobileSubscriptionActivateSimCard } from "./pages/mobile/mobile-subscription-activate-sim-card";
import { MobileSubscriptionOrderAdditionalSimCard } from "./pages/mobile/mobile-subscription-order-additional-sim-card";
import { MobileSubscriptionOrderBlankSimCard } from "./pages/mobile/mobile-subscription-order-blank-sim-card";
import { MutationResponseProvider } from "hooks/useMutationResponse";

export const broadbandRoutes = [
  {
    path: "internett",
    element: <Broadband />,
    handle: {
      crumb: "Internett",
    },
    children: [
      { index: true, element: <BroadbandDashboardNew /> },
      {
        path: "mobilt-bredband",
        children: [
          {
            index: true,
            element: (
              <AdditionalProductsProvider>
                <MutationResponseProvider>
                  <MBBDashboard />
                </MutationResponseProvider>
              </AdditionalProductsProvider>
            ),
            handle: { crumb: "Mobilt bredbånd" },
          },
          {
            path: "tidligere-dataforbruk",
            element: <MobileSubscriptionDataUsageHistory />,
            handle: {
              crumb: "Tidligere dataforbruk",
            },
          },

          {
            path: "sim-pin-puk",
            element: (
              <MutationResponseProvider>
                <Outlet />
              </MutationResponseProvider>
            ),
            children: [
              {
                index: true,
                element: <MobileSubscriptionSimPinPuk />,
                handle: {
                  crumb: "SIM, PIN & PUK",
                },
              },
              { path: "bestill-erstatning", element: <MobileSubscriptionOrderBlankSimCard /> },
              { path: "aktivere-sim", element: <MobileSubscriptionActivateSimCard /> },
              { path: "bestill-sim", element: <MobileSubscriptionOrderAdditionalSimCard /> },
            ],
          },
          {
            path: "innstillinger",
            element: <MobileSubscriptionSettings />,
            handle: {
              crumb: "Innstillinger",
            },
          },
          {
            path: "kjopshistorikk",
            element: <PrepaidOrderHistory />,
            handle: {
              crumb: "Kjøpshistorikk",
            },
          },
        ],
      },
      {
        path: "wifi-nettverk",
        element: <WifiNetworkNew />,
        handle: {
          crumb: "Wifi nettverk",
        },
      },
      {
        path: "tilleggstjenester",
        element: <AdditionalServicesBroadband />,
        handle: {
          crumb: "Tilleggstjenester",
        },
      },
      {
        path: "utstyr/kjop-smart-wifi",
        element: <BuyInternetEquipmentNew />,
        handle: {
          crumb: "Bestill Telia Smart Wifi",
        },
      },
    ],
  },
];
