type Config = {
  nodeEnv: "development" | "production" | "test";
  channelApiUrl: string;
  channelApiWsUrl: string;
  channelApiClientId: string;
  channelApiKey: string;
  channelApiRestUrl: string;
  isNewInvoice: boolean;
  isDownTimeExternalService: boolean;
  isDownTimeInternalService: boolean;
  isDownTimeInternalSystem: boolean;
  showInternalServiceWarning: boolean;
  showInternalServiceWarning_TV: boolean;
  showInternalServiceWarning_VAS: boolean;
  showPartialPay: boolean;
  showFwaAntichurn: boolean;
  showFwaDiscount: boolean;
  showMduForm: boolean;
  showB2b2cCard: boolean;
  showFreeSoloNewBroadband: boolean;
  enableEsimInstallationRedirect: boolean;
  disableSummerCampaignDoubleData: boolean;
  requireBankId: boolean;
  isTempAccessEnabled: boolean;
  isNewBlueconicEnabled: boolean;
  showNewSideMenu: boolean;
  isHint2Enabled: boolean;
  showSubInvoiceDownload: boolean;
  showTvCompensation: boolean;
  isNewProductPageEnabled: boolean;

  teliaEnv: string;
  gitHash: string;
  identityAuthority: string;
  teliaNoRootUrl: string;
  legacyMinSideRootUrl: string;
  daasPortalUrl: string;
  daasBusinessUnitId: string;
  daasPartyRoleNameProductUser: string;
  daasPartyRoleNameProspect: string;
  showTeliaSky: string;
  teliaSkyLoginUrl: string;
  teliaTryggPhoneNumber: string;
  useFakeToken: boolean;
  showAnnualDiscount: boolean;
  showNewPrepaidPage: boolean;
};

export const config: Config = {
  nodeEnv: import.meta.env.VITE_NODE_ENV || import.meta.env.NODE_ENV || "production",
  teliaEnv: import.meta.env.VITE_TELIA_ENV || "",
  gitHash: import.meta.env.VITE_GIT_HASH || "",
  channelApiUrl: import.meta.env.VITE_CHANNEL_API_URL || "",
  channelApiWsUrl: import.meta.env.VITE_CHANNEL_API_WS_URL || "",
  channelApiClientId: import.meta.env.VITE_CHANNEL_API_CLIENT_ID || "",
  channelApiKey: import.meta.env.VITE_CHANNEL_API_KEY || "",
  channelApiRestUrl: import.meta.env.VITE_CHANNEL_API_REST_URL || "",
  isNewInvoice: import.meta.env.VITE_NEW_INVOICE === "true",
  isDownTimeExternalService: import.meta.env.VITE_DOWN_TIME_EXTERNAL_SERVICE === "true",
  isDownTimeInternalService: import.meta.env.VITE_DOWN_TIME_INTERNAL_SERVICE === "true",
  isDownTimeInternalSystem: import.meta.env.VITE_DOWN_TIME_INTERNAL_SYSTEM === "true",
  showInternalServiceWarning: import.meta.env.VITE_WARNING_INTERNAL_SERVICE === "true",
  showInternalServiceWarning_TV: import.meta.env.VITE_WARNING_INTERNAL_SERVICE_TV === "true",
  showInternalServiceWarning_VAS: import.meta.env.VITE_WARNING_INTERNAL_SERVICE_VAS === "true",
  showPartialPay: import.meta.env.VITE_SHOW_PARTIAL_PAY === "true",
  showFwaAntichurn: import.meta.env.VITE_SHOW_FWA_ANTICHURN === "true",
  showFwaDiscount: import.meta.env.VITE_SHOW_FWA_DISCOUNT === "true",
  showMduForm: import.meta.env.VITE_SHOW_MDU_FORM === "true",
  showTvCompensation: import.meta.env.VITE_TV_COMPENSATION === "true",
  showFreeSoloNewBroadband: import.meta.env.VITE_FREE_SOLO_SHOW_NEW_BROADBAND === "true",
  enableEsimInstallationRedirect: import.meta.env.VITE_ESIM_INSTALLATION_REDIRECT_ENABLED === "true",
  identityAuthority: import.meta.env.VITE_IDENTITY_AUTHORITY || "",
  teliaNoRootUrl: import.meta.env.VITE_TELIA_NO_ROOT_URL || "",
  legacyMinSideRootUrl: import.meta.env.VITE_LEGACY_MIN_SIDE_ROOT_URL || "",
  daasPortalUrl: import.meta.env.VITE_DAAS_PORTAL_URL || "",
  daasBusinessUnitId: import.meta.env.VITE_DAAS_BUSINESS_UNIT_ID || "",
  daasPartyRoleNameProductUser: import.meta.env.VITE_DAAS_PARTY_ROLE_NAME_PRODUCT_USER || "",
  daasPartyRoleNameProspect: import.meta.env.VITE_DAAS_PARTY_ROLE_NAME_PROSPECT || "",
  showTeliaSky: import.meta.env.VITE_SHOW_TELIA_SKY || "",
  teliaSkyLoginUrl: import.meta.env.VITE_TELIA_SKY_LOGIN_URL || "",
  teliaTryggPhoneNumber: "80 04 16 00",
  useFakeToken: import.meta.env.VITE_USE_FAKE_TOKEN === "true",
  disableSummerCampaignDoubleData: import.meta.env.VITE_DISABLE_SUMMER_CAMPAIGN_DOUBLE_DATA === "true",
  requireBankId: import.meta.env.VITE_REQUIRE_BANK_ID === "true",
  isTempAccessEnabled: import.meta.env.VITE_TEMP_ACCESS_ENABLED === "true",
  isNewBlueconicEnabled: import.meta.env.VITE_NEW_BLUECONIC_UI === "true",
  showNewSideMenu: import.meta.env.VITE_SHOW_NEW_SIDE_MENU === "true",
  showB2b2cCard: import.meta.env.VITE_SHOW_B2B2C_CARD === "true",
  isHint2Enabled: import.meta.env.VITE_HINT_2_ENABLED === "true",
  showSubInvoiceDownload: import.meta.env.VITE_SHOW_SUBINVOICE_DOWNLOAD === "true",
  showAnnualDiscount: import.meta.env.VITE_SHOW_ANNUAL_DISCOUNT === "true",
  showNewPrepaidPage: (() => {
    const override = localStorage.getItem("override_new_prepaid_page");
    return override !== null ? override === "true" : import.meta.env.VITE_SHOW_NEW_PREPAID_PAGE === "true";
  })(),
  isNewProductPageEnabled: (() => {
    const override = localStorage.getItem("override_new_product_page");
    return override !== null ? override === "true" : import.meta.env.VITE_NEW_PRODUCT_PAGE_ENABLED === "true";
  })(),
};
