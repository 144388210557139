import { useQuery } from "@apollo/client";
import { Card, Drawer, Flex, Skeleton, Icon } from "@telia/teddy";
import { ErrorBox } from "components/ErrorBox";
import { useMobileAccount } from "hooks/useMobileAccount";
import { AdditionalProductPaymentType } from "gql/graphql";
import { Topup } from "pages/mobile/mobile-subscription-dashboard/modules/TopupDrawer/components/Topup";
import { getTopupDisplayInfo } from "pages/mobile/mobile-subscription-dashboard/modules/TopupModal/utils";
import { EmptyTopupNotification } from "pages/mobile/mobile-subscription-dashboard/modules/TopupDrawer/components/EmptyTopupNotification";
import {
  getDataPackagesDescription,
  isDataBoostTopup,
  isDataPackageTopup,
} from "pages/mobile/mobile-subscription-dashboard/modules/TopupDrawer/utils";
import { useMBBSubscriptionData } from "pages/broadbandNew/hooks/useMBBSubscriptionData";
import { GET_TOPUPS } from "pages/mobile/mobile-subscription-dashboard/modules/TopupDrawer/graphql";

export function TopupDrawerContent() {
  const { phoneNumber, accountType } = useMobileAccount();
  const { handleTopupSelection, topupCategory } = useMBBSubscriptionData();
  const isDataBoost = topupCategory === "Boost";

  const { data, loading, error, refetch } = useQuery(GET_TOPUPS, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  if (loading) return <Loading />;
  if (error) return <Error refetch={refetch} />;

  const subscription = data?.subscription;
  const isB2B = accountType === "B2B";

  const selectedTopups = subscription?.topups
    ?.filter((topup) => !topup.hiddenInGeneralTopupList)
    ?.filter(isDataBoost ? isDataBoostTopup : isDataPackageTopup)
    ?.filter((topup) =>
      isB2B && "paymentType" in topup ? topup.paymentType === AdditionalProductPaymentType.Vipps : true
    )
    ?.filter((topup) => !topup.id.includes("GIFT"));

  const title = isDataBoost ? "Data Boost" : "Datapakker";
  const description = isDataBoost
    ? "Data Boost-pakker gir deg ubegrenset med data, fra kjøp og til pakken går ut."
    : getDataPackagesDescription(selectedTopups || []);

  return (
    <>
      <Drawer.Close aria-label="Close" slot="floating" />
      <Drawer.Title variant="title-200">{title}</Drawer.Title>
      <Card.Line />
      <Drawer.Description>{description}</Drawer.Description>
      <Flex direction="column" gap="gutter-sm">
        {selectedTopups && selectedTopups.length > 0 ? (
          selectedTopups.map((topup) => {
            const value = topup.name.split(" ")[0];
            const valueUnit = topup.name.split(" ")[1];
            const topupInfo = getTopupDisplayInfo(topup);
            const hasVouchers = topupInfo.numberOfVouchers && topupInfo.numberOfVouchers > 0;
            const vouchersNumber = hasVouchers ? topupInfo.numberOfVouchers : 0;
            const price = "price" in topup ? topup.price?.valueWithVat : undefined;
            const isVippsPayment =
              "paymentType" in topup ? topup.paymentType === AdditionalProductPaymentType.Vipps : false;

            return (
              <Topup
                key={topup.id + topup.name}
                value={value}
                valueUnit={valueUnit}
                period={isDataBoost ? "Data Boost" : "Datapakke"}
                price={price}
                buttonText={hasVouchers ? "Aktiver" : "Kjøp"}
                onClick={() => handleTopupSelection(topup)}
                vouchersNumber={vouchersNumber}
                isVippsPayment={isVippsPayment}
              />
            );
          })
        ) : (
          <EmptyTopupNotification />
        )}
      </Flex>
    </>
  );
}

function Loading() {
  return (
    <Flex direction="column" gap="gutter-sm">
      <Skeleton height="40px" width="100%" />
      <Skeleton height="1px" width="100%" />
      <Skeleton height="72px" width="100%" />

      <Flex direction="column" gap="gutter-sm">
        <Skeleton height="90px" width="100%" />
        <Skeleton height="90px" width="100%" />
        <Skeleton height="90px" width="100%" />
      </Flex>
    </Flex>
  );
}

function Error({ refetch }: { refetch: () => void }) {
  return (
    <ErrorBox
      title="Vi beklager"
      description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
      actionContent={<Icon name="sync">Last side på nytt</Icon>}
      onClick={() => refetch()}
    />
  );
}
