import * as React from "react";
import { Alert } from "@telia/styleguide";
import { Container, Heading } from "@telia-no-min-side/components";

type IErrorMessageProps = {
  errorText: string;
  children?: React.ReactNode;
};

export function ErrorMessage({ errorText, children }: IErrorMessageProps) {
  return (
    <PageWrapper>
      <Alert kind="negative">
        <p>{errorText}</p>
      </Alert>

      {children && (
        <Container>
          <p className="paragraph" style={{ marginBottom: "5rem" }}>
            {children}
          </p>
        </Container>
      )}
    </PageWrapper>
  );
}

type IPageWrapperProps = {
  preambleText?: string;
  children: React.ReactNode;
};

export function PageWrapper({ children }: IPageWrapperProps) {
  return (
    <>
      <Container showGoBackButton padding gap flexDirection="column">
        <Heading variant="title-300" tag="h1">
          Detaljert bruk
        </Heading>
        {children}
      </Container>
    </>
  );
}
