import { Card, Flex, Grid, Heading, Icon, IconName, Text, GridProps } from "@telia/teddy";
import { Link } from "react-router-dom";
import { config } from "util/config";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { useMBBSubscriptionData } from "../../hooks/useMBBSubscriptionData";

type DataType = { condition: boolean; link: string; icon: IconName; title: string; description: string };
type Props = {
  itemColumns?: GridProps["columns"];
};

export function AdminSection({ itemColumns = "1" }: Props) {
  const { isPrepaid } = useMBBSubscriptionData();

  const data: DataType[] = [
    {
      condition: true,
      link: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_SIM_CARD_PAGE,
      icon: "simcard",
      title: "SIM, PIN & PUK",
      description: "Administrer, bestill, sperr og aktiver dine SIM-kort",
    },
    {
      condition: true,
      link: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_SETTINGS_PAGE,
      icon: "settings",
      title: "Innstillinger",
      description: "Administrer dine innstillinger",
    },
    {
      condition: isPrepaid && config.showNewPrepaidPage,
      link: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_PREPAID_ORDER_HISTORY,
      icon: "summary",
      title: "Kjøpshistorikk",
      description: "Se oversikt over dine kjøp",
    },
  ];
  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Administrer
      </Heading>

      <Grid columns={itemColumns} gap="gutter-xl">
        {data.map(
          (item) =>
            item.condition && (
              <Card key={item.title} layout="navigation-horizontal-small" variant="gray" width="100%">
                <Card.Illustration>
                  <Icon name={item.icon} size="lg" />
                </Card.Illustration>
                <Card.Heading variant="title-100">{item.title}</Card.Heading>
                <Card.Content>
                  <Text>{item.description}</Text>
                </Card.Content>
                <Card.Button aria-label="Proceed to page" asChild iconOnly>
                  <Link to={item.link}>
                    <Icon name="arrow-right" />
                  </Link>
                </Card.Button>
              </Card>
            )
        )}
      </Grid>
    </Flex>
  );
}
