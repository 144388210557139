import { Breadcrumb } from "components/Breadcrumb";
import { AdditionalServices } from "pages/broadbandNew/additional-service/AdditionalServices";

export function AdditionalServicesBroadband() {
  return (
    <>
      <Breadcrumb />
      <AdditionalServices />
    </>
  );
}
