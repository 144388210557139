import { useState } from "react";
import { Flex, Heading, Box } from "@telia/teddy";
import { useFetchProductGroup, useFetchProductsAdditionalInfo } from "src/api/fixed";
import { availableSmartWifiProducts } from "pages/broadbandNew/utils/smartWifi";
import { OverdueInvoiceWarning } from "src/components/overdue-invoice";
import { useFixedInvoice } from "src/hooks/useFixedInvoice";
import { OrderSummary } from "./OrderSummary";
import { SmartWifiExtender } from "./SmartWifiExtender";
import { SmartWifiRouter } from "./SmartWifiRouter";
import { TeliaWifiRouter } from "./TeliaWifiRouter";
import { Breadcrumb } from "components/Breadcrumb";
import { ErrorBox } from "components/ErrorBox";
import { ShoppingCard } from "components/shopping-card";

export type Order = {
  axiomIds: {
    axiomId: number;
    quantity: number;
    addonIds: [];
  }[];
  shippingMethodId: number;
};

export function BuyInternetEquipment() {
  const grpSmartWifi = useFetchProductGroup("grp_smart_wifi");
  const productsAdditionalInfo = useFetchProductsAdditionalInfo();
  const { isInvoiceOverdueOrInCollection } = useFixedInvoice();
  const availableProducts = availableSmartWifiProducts(grpSmartWifi.data);
  const { smartWifiExtender, smartWifiRouter, teliaWifiRouter } = availableProducts;
  const [quantityExtender, setQuantityExtender] = useState(0);
  const hasToSelectRouter = smartWifiRouter && teliaWifiRouter;
  const [selectedRouter, setSelectedRouter] = useState<"smartWifiRouter" | "teliaWifiRouter" | undefined>(undefined);
  const productBoxWidth = { sm: "100%", lg: "60%" };
  const orderSummaryWidth = { sm: "100%", lg: "30%" };
  const pageTitle = smartWifiRouter
    ? "Bestill Telia Smart Wifi"
    : smartWifiExtender
    ? "Bestill Telia Ekstender"
    : "Bestill Telia Wifi";

  return (
    <>
      <Breadcrumb />
      <Heading as="h2" variant="title-300" mt="300" mb="250">
        {pageTitle}
      </Heading>
      <Flex gap="200" wrap="wrap" mt="200">
        {grpSmartWifi.isLoading || productsAdditionalInfo.isLoading ? (
          <ShoppingCard.Skeleton hasImage hasTitle hasBody hasFooter />
        ) : !grpSmartWifi.data?.length || !productsAdditionalInfo.data?.canOrderInternetEquipment ? (
          <ErrorBox
            title="Beklager"
            description={
              <>
                Du har dessverre ikke tilgang til å kjøpe <em>Telia Smart Wifi</em> på nåværende tidspunkt.
              </>
            }
            width={{ md: "100%", lg: "40%" }}
          />
        ) : hasToSelectRouter ? (
          <>
            <Flex gap="200" direction="column" width={productBoxWidth}>
              {teliaWifiRouter && (
                <TeliaWifiRouter
                  shouldDisableSelect={isInvoiceOverdueOrInCollection}
                  product={teliaWifiRouter}
                  isSelected={selectedRouter === "teliaWifiRouter"}
                  onSelectClick={() => {
                    setSelectedRouter("teliaWifiRouter");
                    setQuantityExtender(0);
                  }}
                />
              )}
              {smartWifiRouter && (
                <SmartWifiRouter
                  shouldDisableSelect={isInvoiceOverdueOrInCollection}
                  product={smartWifiRouter}
                  isSelected={selectedRouter === "smartWifiRouter"}
                  onSelectClick={() => setSelectedRouter("smartWifiRouter")}
                />
              )}
              {selectedRouter === "smartWifiRouter" && smartWifiExtender && (
                <SmartWifiExtender
                  shouldHideQuantitySelector={isInvoiceOverdueOrInCollection}
                  product={smartWifiExtender}
                  quantity={quantityExtender}
                  setQuantity={setQuantityExtender}
                />
              )}
              <OverdueInvoiceWarning />
            </Flex>
            <Box width={orderSummaryWidth}>
              {(selectedRouter || quantityExtender > 0) && !isInvoiceOverdueOrInCollection && (
                <OrderSummary
                  selectedRouter={selectedRouter === "smartWifiRouter" ? smartWifiRouter : teliaWifiRouter}
                  smartExtender={smartWifiExtender}
                  quantityExtender={quantityExtender}
                />
              )}
            </Box>
          </>
        ) : (
          <>
            <Flex gap="200" direction="column" width={productBoxWidth}>
              {teliaWifiRouter && (
                <TeliaWifiRouter
                  shouldDisableSelect={isInvoiceOverdueOrInCollection}
                  product={teliaWifiRouter}
                  isSelected
                  onSelectClick={() => {
                    setQuantityExtender(0);
                  }}
                />
              )}
              {smartWifiRouter && (
                <SmartWifiRouter
                  shouldDisableSelect={isInvoiceOverdueOrInCollection}
                  product={smartWifiRouter}
                  isSelected
                />
              )}
              {smartWifiExtender && (
                <SmartWifiExtender
                  shouldHideQuantitySelector={isInvoiceOverdueOrInCollection}
                  product={smartWifiExtender}
                  quantity={quantityExtender}
                  setQuantity={setQuantityExtender}
                />
              )}
              <OverdueInvoiceWarning />
            </Flex>
            <Box width={orderSummaryWidth}>
              {!isInvoiceOverdueOrInCollection && (
                <OrderSummary
                  selectedRouter={smartWifiRouter || teliaWifiRouter}
                  smartExtender={smartWifiExtender}
                  quantityExtender={quantityExtender}
                />
              )}
            </Box>
          </>
        )}
      </Flex>
    </>
  );
}
