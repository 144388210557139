import React, { ReactNode, useState } from "react";
import { Container } from "../Container";
import { Button } from "../Button";
import { Box } from "./style";

export type Props = {
  kind?: "warning" | "error";
  showCloseButton?: boolean;
  onClose?: () => void;
  children: ReactNode;
};

export function NotificationBox(props: Props): JSX.Element | null {
  const { kind, showCloseButton = true, children } = props;
  const [isClosed, setIsClosed] = useState(false);

  function onClose() {
    setIsClosed(true);
    if (props.onClose) {
      props.onClose();
    }
  }
  return isClosed ? null : (
    <Container maxWidth="md" horizontalPosition="center">
      <Box kind={kind}>
        <div>{children}</div>
        {showCloseButton && (
          <Button variant="tertiary-black" icon="close" isIconButton title="Lukk" onClick={onClose} />
        )}
      </Box>
    </Container>
  );
}
