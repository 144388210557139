import { Accordion, Button, Container, Link } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { Product } from "@telia-no-min-side/utils/src/types/fixed";
import { Alert } from "@telia/styleguide";
import { useAdditionalServices } from "pages/broadbandNew/hooks/useAdditionalServices";
import { getBadgeInfo } from "pages/broadbandNew/utils/getBadgeInfo";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { getAccordionTrackingEvent } from "pages/broadbandNew/utils/tracking";
import { HeaderWrapper, Heading, InformationText } from "pages/broadbandNew/styles/style";

type Props = {
  product: Product;
};

export function IDTyveriForsikring({ product }: Props) {
  const orderUrl = product.actions?.find((action) => action.type === "ORDER")?.url;

  const { activationStatus, activateService } = useAdditionalServices(product);

  return (
    <Accordion.Item
      id="TPN-help"
      tracking={getAccordionTrackingEvent("ID Tyveri Forsikring")}
      lozengeRight={getBadgeInfo(product)}
      dataTrackingID="additional-services-help"
    >
      <Accordion.Trigger>
        <HeaderWrapper>
          <Heading>ID Tyveri Forsikring</Heading>
        </HeaderWrapper>
      </Accordion.Trigger>
      <Accordion.Content>
        <InformationText>
          Havner du i en situasjon der du eller noen i husstanden din trenger råd eller hjelp, kan du kontakte HELP
          umiddelbart på <a href="tel:22999999">22999999</a> eller <a href="mailto:post@help.no">post@help.no</a>.
        </InformationText>
        <InformationText>
          Mer informasjon om forsikringen finner du på{" "}
          <Link target="_blank" href={EXTERNAL_LINK.HELP}>
            help.no
          </Link>
        </InformationText>

        {orderUrl && (
          <Container padding="top" floatContent="right">
            <Button tag="a" size="sm" variant="primary" href={uri.purchase(orderUrl)}>
              Bestill
            </Button>
          </Container>
        )}
        {product.status?.active === false && !orderUrl && (
          <Container padding="top" floatContent="right">
            <Button variant="primary" size="sm" isLoading={activationStatus === "activating"} onClick={activateService}>
              Aktiver
            </Button>
          </Container>
        )}
        {activationStatus === "error" && (
          <Container padding="top">
            <Alert kind="negative">
              <p>En feil oppstod under aktivering, vennligst prøv igjen eller ta kontakt med kundeservice.</p>
            </Alert>
          </Container>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
}
