import { ReactNode } from "react";
import { uri, types } from "@telia-no-min-side/utils";
import { useAxiosSWR } from "src/services/axios/useAxiosSWR";
import { useFetchBroadbandSubscription } from "../useFetchBroadbandSubscription";
import { getNotificationFromOperationalMessages } from "./operationalMessages";
import { getNotificationFromStatusMessages } from "./statusMessages";
import { getNotificationUnlinkedModem } from "./unlinkedModem";
import { getNotificationProductStatusAbused } from "./productStatusAbused";
import { ICX_REQUEST } from "util/constants/ICX_REQUEST";

const linkableDeviceUrl = uri.purchase(ICX_REQUEST.URL_LINKABLE_DEVICE);

export type NotificationData = {
  type:
    | types.fixed.StatusMessage["type"]
    | "operational-messages"
    | "unlinked-modem"
    | "broadband-subscription-abused"
    | "price-warning";
  title: string;
  message: ReactNode;
  link?: {
    url: string;
    isInternalLink: boolean;
    text: string;
  };
};

export function useFetchNotification() {
  const { data: statusMessages, error: errorStatusMessages } = useAxiosSWR<types.fixed.StatusMessages>(
    ICX_REQUEST.URL_STATUS_MESSAGE
  );
  const { data: operationalMessages, error: errorOperationalMessages } = useAxiosSWR<types.fixed.OperationalMessages>(
    ICX_REQUEST.URL_OPERATIONAL_STATUS
  );
  const { data: linkableDevices, error: errorLinkableDevices } =
    useAxiosSWR<types.fixed.LinkingDevices>(linkableDeviceUrl);
  const { data: broadbandSubscription, isLoading: broadbandSubscriptionIsLoading } = useFetchBroadbandSubscription();

  const data: NotificationData[] = [
    ...getNotificationProductStatusAbused(broadbandSubscription?.products.map((product) => product.status)),
    ...getNotificationFromStatusMessages(statusMessages),
    ...getNotificationFromOperationalMessages(operationalMessages),
    ...getNotificationUnlinkedModem(linkableDevices),
  ];

  return {
    data,
    isLoading:
      !broadbandSubscriptionIsLoading &&
      !errorStatusMessages &&
      !statusMessages &&
      !errorOperationalMessages &&
      !operationalMessages &&
      !errorLinkableDevices &&
      !linkableDevices,
  };
}
