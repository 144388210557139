import { useEffect, ReactNode } from "react";
import { Container } from "@telia-no-min-side/components";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { getMobileSubscriptions } from "../utils/getMobileSubscriptions";
import { MobileSubscriptionPicker } from "src/components/profile-pickers/MobileSubscriptionPicker";
import { Subscription } from "src/components/profile-pickers/hooks/useProfilePicker";

type Props = {
  subscriptions: ReturnType<typeof getMobileSubscriptions>;
  children?: ReactNode;
};

export function DashboardPicker({ subscriptions, children }: Props) {
  const { phoneNumber, setPhoneNumber, setAccountId } = useMobileAccount();

  useEffect(() => {
    const selectedSubscription = subscriptions.find(
      (subscription) => subscription?.phoneNumber.localNumber === phoneNumber
    );

    if (!selectedSubscription) {
      setAccountId(subscriptions[0]?.account.id || "");
      setPhoneNumber(subscriptions[0]?.phoneNumber.localNumber || "");
    }
  }, []);

  const handleChange = (phoneNumber: string, profile: Subscription) => {
    setAccountId(profile.account.id);
    setPhoneNumber(phoneNumber);
  };

  return (
    <Container padding>
      <MobileSubscriptionPicker selectedProfileId={phoneNumber} onProfileSelect={handleChange}>
        {children}
      </MobileSubscriptionPicker>
    </Container>
  );
}
