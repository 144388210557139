import { PropsWithChildren, createContext, FC, useContext, useState, useEffect, useCallback } from "react";
import { ApolloError, useQuery } from "@apollo/client";
import { useMobileAccount } from "hooks/useMobileAccount";
import { getDownloadSpeedMbb } from "util/format-subscription/getDownloadSpeedMbb";
import { getOfferingPrices } from "util/format-subscription/getOfferingPrices";
import { GET_MBB_SUBSCRIPTION_DATA } from "../dashboard/graphql/getMBBSubscriptionData";
import { getMbbName } from "util/format-subscription/getMbbName";
import { getIncludedPropertiesMbb } from "util/format-subscription/getIncludedPropertiesMbb";
import { isMbbSleepModeOffer } from "util/format-subscription/isMbbSleepModeOffer";
import { formatTimeUntilDate } from "../dashboard/components/DataUsageCard/utils";
import { useMBBDataUsage } from "./useMBBDataUsage";
import { isMbbUnlimitedDataOffer } from "util/format-subscription/isMbbUnlimitedDataOffer";
import { isPrepaidAccount } from "util/mobile/prepaid";
import {
  AdditionalProductPaymentType,
  ConfirmPaymentStatus,
  GetMbbSubscriptionDataQuery,
  OrderOp,
  PaymentProvider,
} from "gql/graphql";
import { track } from "@telia-no-min-side/utils";
import { useMutationResponse } from "hooks/useMutationResponse";
import { useAdditionalProducts } from "hooks/useAdditionalProducts";
import { useConfirmPayment } from "pages/mobile/mobile-subscription-dashboard/hooks/useConfirmPayment";
import { TopupLocalType } from "pages/mobile/mobile-subscription-dashboard/types";
import { datadogRum } from "@datadog/browser-rum";
import {
  getTopupTransactionData,
  isPaidTopupHandler,
} from "pages/mobile/mobile-subscription-dashboard/modules/TopupDrawer/utils";
import { useSearchParams } from "react-router-dom";
import { ArrayElement } from "pages/mobile/mobile-subscription-dashboard/modules/DataUsageCard/utils/sortDataUsage";
import { IncludedProperty } from "../dashboard/components/HvilemodusDrawerContent";

type MBBDashboard = {
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  subHeading: string | null;
  monthlyPrice: number | undefined;
  originalMonthlyPrice: number | null | undefined;
  subscriptionName: string | null | undefined;
  includedProperties: IncludedProperty[];
  changeSubscriptionLink: string;
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
  isUnlimitedDataSubscription: boolean;
  isPrepaid: boolean;
  isSleepMode: boolean;
  drawerContent: "RLH" | "Hvilemodus" | "Topup" | undefined;
  setDrawerContent: (drawerContent: "RLH" | "Hvilemodus" | "Topup" | undefined) => void;
  subscription: GetMbbSubscriptionDataQuery["subscription"] | undefined;
  timeUntilDataUsagePeriodStarts: string | null;
  domesticalUsage: GetMbbSubscriptionDataQuery["subscription"]["usage"];
  orderTopup: (topup?: TopupLocalType) => void;
  handleTopupSelection: (topup?: TopupLocalType) => void;
  selectedTopup: TopupLocalType | undefined;
  setSelectedTopup: (topup?: TopupLocalType) => void;
  paymentStatus: ConfirmPaymentStatus | undefined;
  purchaseError: string;
  activeDataBoost: ArrayElement<GetMbbSubscriptionDataQuery["subscription"]["usage"]> | undefined;
  topupCategory: "Boost" | "Package" | undefined;
  setTopupCategory: (category?: "Boost" | "Package") => void;
  hasCoverageGuarantee: boolean | undefined;
};

const MBBDashboardContext = createContext<MBBDashboard>({
  loading: false,
  error: undefined,
  refetch: () => undefined,
  subHeading: null,
  monthlyPrice: undefined,
  originalMonthlyPrice: undefined,
  subscriptionName: undefined,
  includedProperties: [],
  changeSubscriptionLink: "",
  isDrawerOpen: false,
  closeDrawer: () => {},
  openDrawer: () => {},
  isUnlimitedDataSubscription: false,
  isPrepaid: false,
  isSleepMode: false,
  drawerContent: undefined,
  setDrawerContent: () => {},
  subscription: undefined,
  timeUntilDataUsagePeriodStarts: null,
  domesticalUsage: null,
  orderTopup: () => {},
  handleTopupSelection: () => {},
  selectedTopup: undefined,
  setSelectedTopup: () => {},
  paymentStatus: undefined,
  purchaseError: "",
  activeDataBoost: undefined,
  topupCategory: undefined,
  setTopupCategory: () => {},
  hasCoverageGuarantee: undefined,
});

const COVERAGE_GUARANTEE_PRODUCT_CODE = "NSHAPE351";

export const MBBDashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const { accountId, phoneNumber, isLoading: isLoadingAccounts } = useMobileAccount();
  const [purchaseId, setPurchaseId] = useState("");
  const [selectedTopup, setSelectedTopup] = useState<TopupLocalType | undefined>();
  const [topupCategory, setTopupCategory] = useState<"Boost" | "Package" | undefined>();
  const { pushTrackingEvent } = track.useEventTracking();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setMutationResponseMessage } = useMutationResponse();
  const { runOrderAdditionalProducts } = useAdditionalProducts();

  // Try confirming purchase if purchaseId was sent as an url parameter
  const { response: confirmPaymentResponse, runConfirmPayment } = useConfirmPayment();
  const paymentStatus = confirmPaymentResponse.data?.confirmPayment?.result;

  const result = useQuery(GET_MBB_SUBSCRIPTION_DATA, {
    variables: { phoneNumber },
    notifyOnNetworkStatusChange: true,
    skip: !phoneNumber,
  });

  const { isLoading: isLoadingUsage, subscription, purchaseError, setPurchaseError } = useMBBDataUsage(result);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<"RLH" | "Hvilemodus" | "Topup" | undefined>(undefined);

  const offeringPrices = getOfferingPrices(subscription?.userOffering?.offeringPrices);
  const isSleepMode = isMbbSleepModeOffer(subscription?.userOffering?.shortName);
  const isUnlimitedDataSubscription = isMbbUnlimitedDataOffer(offeringPrices, subscription?.userOffering?.shortName);
  const downloadSpeed = getDownloadSpeedMbb(offeringPrices, subscription?.userOffering?.longDescription);
  const subscriptionName = getMbbName(offeringPrices, subscription?.userOffering?.shortName);
  const includedProperties = getIncludedPropertiesMbb(
    subscription?.userOffering?.shortName,
    downloadSpeed,
    subscription?.userOffering?.additionalProperties,
    subscription?.userOffering?.disclaimers,
    offeringPrices
  ) as IncludedProperty[];

  const isForeign = (service: string) => service.includes("SONE") || service.includes("USA");
  const dataUsage = subscription?.usage?.filter((quota) => quota?.category?.startsWith("DATA")) || [];

  const hasCoverageGuarantee = subscription?.additionalProducts?.some(
    (product) => product.code === COVERAGE_GUARANTEE_PRODUCT_CODE
  );

  // const foreignUsage = dataUsage?.filter((quota) => isForeign(quota?.service || "")) || [];

  const domesticalUsage = dataUsage?.filter((quota) => !isForeign(quota?.service || ""));
  const activeDataBoost = domesticalUsage?.find((quota) => quota?.activeDataBoostSession === true);

  const period = domesticalUsage?.find((usage) => usage?.type === "BASE")?.period;
  const timeUntilDataUsagePeriodStarts = formatTimeUntilDate(period?.endDate);

  const getSubHeading = () => {
    if (!!downloadSpeed && isUnlimitedDataSubscription) return `${downloadSpeed} Mbps - Ubegrenset data`;
    if (isUnlimitedDataSubscription) return "Ubegrenset data";
    if (downloadSpeed) return `${downloadSpeed} Mbps`;
    return null;
  };
  const subHeading = getSubHeading();

  const changeSubscriptionLink = `/minside/mobilt-bredband/endre/${accountId}/${phoneNumber}`;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  // TODO
  // const additionalProperties = (subscription?.userOffering as UserOffering)?.additionalProperties || [];

  useEffect(() => {
    // When user is being redirected back to /minside/forbruk?purchaseId=${purchaseId}&product=${productId}
    // data is being extracted for order confirmation and url parameters should be removed
    const purchaseId = searchParams.get("purchaseId") || "";
    const product = searchParams.get("product");
    if (purchaseId || product) {
      setPurchaseId(purchaseId);
      searchParams.delete("purchaseId");
      searchParams.delete("product");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (!purchaseId) return;
    runConfirmPayment({ purchaseId, provider: PaymentProvider.Vipps });
  }, [purchaseId]);

  const handleTopupSelection = useCallback(
    (topup?: TopupLocalType) => {
      if (topup) datadogRum.addAction("select_topup", { ...topup });
      setSelectedTopup(topup);
      setMutationResponseMessage((prev) => ({ ...prev, isSuccessfulMutation: false }));
      setPurchaseError("");
    },
    [setSelectedTopup, setPurchaseError]
  );

  const composeReturnUrl = (location: Location, selectedTopup?: TopupLocalType) =>
    location.search
      ? `${location.href}&purchaseId={purchase_id}&product=${selectedTopup?.id}`
      : `${location.href}?purchaseId={purchase_id}&product=${selectedTopup?.id}`;

  const orderTopup = (topup?: TopupLocalType) => {
    if (!topup) return;
    const returnUrl = composeReturnUrl(window.location, topup);
    const isPaidTopup = isPaidTopupHandler(topup);

    const orderInput = {
      operation: OrderOp.AddAdditionalProduct,
      productName: topup.id,
      topupType: topup.type,
      paymentInfo: {
        paymentType: isPaidTopup && topup.paymentType ? topup.paymentType : AdditionalProductPaymentType.Invoice,
        returnUrl,
      },
    };
    datadogRum.addAction("buy_topup", { ...orderInput });

    if (runOrderAdditionalProducts) {
      runOrderAdditionalProducts(
        {
          phoneNumber,
          input: [orderInput],
        },
        {
          onCompleted(data) {
            setSelectedTopup(undefined);
            const orderId = data?.order?.orderId || "";
            const transactionData = getTopupTransactionData(topup, orderId);
            return pushTrackingEvent(transactionData);
          },
        }
      );
    }
  };

  return (
    <MBBDashboardContext.Provider
      value={{
        loading: isLoadingAccounts || isLoadingUsage,
        error: result.error,
        refetch: result.refetch,
        subscription,
        subHeading,
        monthlyPrice: subscription?.userOffering?.monthlyCost?.amount,
        originalMonthlyPrice: subscription?.userOffering?.originalPrice?.amount,
        subscriptionName,
        includedProperties,
        changeSubscriptionLink,
        isDrawerOpen,
        closeDrawer,
        openDrawer,
        isSleepMode,
        isUnlimitedDataSubscription,
        isPrepaid: isPrepaidAccount(subscription?.billingType),
        drawerContent,
        setDrawerContent,
        timeUntilDataUsagePeriodStarts,
        domesticalUsage,
        handleTopupSelection,
        orderTopup,
        selectedTopup,
        setSelectedTopup,
        paymentStatus,
        purchaseError,
        activeDataBoost,
        topupCategory,
        setTopupCategory,
        hasCoverageGuarantee,
      }}
    >
      {children}
    </MBBDashboardContext.Provider>
  );
};

export function useMBBSubscriptionData(): MBBDashboard {
  const context = useContext<MBBDashboard>(MBBDashboardContext);

  if (!context) {
    throw Error(
      "No MobileAccountContext found! This usually happens when you try to access a context outside of a provider"
    );
  }
  return context;
}
