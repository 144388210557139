import { Accordion, Heading, Link, Paragraph, SkeletonList } from "@telia-no-min-side/components";
import { useFetchSafeOnNet } from "src/api/fixed";
import { IDTyveriForsikring } from "./components/IDTyveriForsikring";
import { Nettsikkerhet } from "./components/Nettsikkerhet";
import { TeliaSky } from "./components/TeliaSky";
import { InformationText } from "../styles/style";
import { getSafeOnNetProducts } from "../utils/getSafeOnNetProducts";
import { EXTERNAL_LINK } from "util/constants/externalLinks";

export function AdditionalServices() {
  const { safeOnNet, isLoading, isError } = useFetchSafeOnNet();
  const productsByType = getSafeOnNetProducts(safeOnNet);
  const noProductsAvailable = !productsByType.teliaSky && !productsByType.fSecure && !productsByType.help;

  return (
    <>
      <div>
        <Heading tag="h2" variant="title-100">
          Trygg på nett
        </Heading>
        <InformationText>
          Trygg på nett er din digitale sikkerhetspakke, som gir deg og din familie økt digital sikkerhet. Alle
          tjenestene er gratis for deg som bredbåndkunde i Telia. Du kan enkelt aktivere og deaktivere tjenestene under.
        </InformationText>
        <Paragraph>
          <Link variant="standalone" target="_blank" href={EXTERNAL_LINK.INTERNET_SAFE_ON_NET}>
            Les mer om Trygg på nett
          </Link>
        </Paragraph>
        {!isLoading && !isError && noProductsAvailable && (
          <InformationText>
            Det ser ikke ut som du har noen trygg på nett produkter. Ta kontakt med kundeservice hvis dette er noe du
            kunne tenke deg.
          </InformationText>
        )}
      </div>
      <Accordion gap="lg">
        {isLoading ? (
          <SkeletonList count={3} />
        ) : (
          <>
            {productsByType.teliaSky ? <TeliaSky product={productsByType.teliaSky} /> : null}
            {productsByType.fSecure ? <Nettsikkerhet product={productsByType.fSecure} /> : null}
            {productsByType.help ? <IDTyveriForsikring product={productsByType.help} /> : null}
          </>
        )}
      </Accordion>
    </>
  );
}
