import { Modal, Button, Text, TextSpacing } from "@telia/teddy";
import { INVOICE_POSTPONE_DAYS } from "util/constants/invoices";

type Props = {
  modalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  dueDate: string;
  handlePostponeInvoice: () => void;
  isLoading: boolean;
};

export const ConfirmPostponeInvoiceModal = ({
  modalOpen,
  setIsModalOpen,
  dueDate,
  handlePostponeInvoice,
  isLoading,
}: Props) => {
  return (
    <Modal open={modalOpen}>
      <Modal.Overlay style={{ zIndex: 1000 }}>
        <Modal.Content>
          <Modal.Title as="h3">Utsett betaling i {INVOICE_POSTPONE_DAYS} dager</Modal.Title>
          <Modal.Close slot="floating" aria-label="lukk" onClick={() => setIsModalOpen(false)} />
          <Modal.Description>
            <TextSpacing>
              <Text as="span">
                Du kan utsette betalingen din i {INVOICE_POSTPONE_DAYS} dager mot gebyr på 50 kr. Neste faktura vil
                inneholde både fast månedskostnad, utsatt beløp og gebyr for betalingsutsettelse.
              </Text>
              <Text as="span">Ny forfallsdato blir {dueDate}.</Text>
            </TextSpacing>
          </Modal.Description>
          <Modal.Group variant="action">
            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
              Avbryt
            </Button>
            <Button variant="primary" loading={isLoading} onClick={handlePostponeInvoice}>
              Utsett betalingen
            </Button>
          </Modal.Group>
        </Modal.Content>
      </Modal.Overlay>
    </Modal>
  );
};
