import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { zIndex } from "../../utils/variables";
import { Card } from "../../Card";
import { devices } from "../../utils/breakpoints";
import { Link } from "../../Link";

export const LinkText = styled.span`
  margin-left: 0.5rem;
`;
export const TopSection = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 0.5rem;
  justify-content: center;
`;

export const LinkSection = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const BottomSection = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
  flex-direction: column;
  & span {
    font-weight: 400;
    margin-left: 0.5rem;
  }
`;

export const MinSideButtonWrapper = styled.div`
  z-index: ${zIndex.sideMenuMobile};
  position: relative;
`;

export const DropDown = styled(Card)`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 16rem;
  padding: 0;
  @media ${devices.sm_down} {
    right: -50px;
  }
`;

export const StyledLink = styled(Link)`
  margin-left: 0.8rem;
  margin-top: 1rem;
`;
