import { Outlet } from "react-router-dom";
import { BroadbandTopMenu } from "components/top-menu/BroadbandTopMenu";
import { PageContainer } from "components/PageContainer";

export function Broadband() {
  return (
    <>
      <BroadbandTopMenu dataTrackingID="dashboard-broadband-profile-picker" />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </>
  );
}
