import dayjs from "dayjs";
import { Button, Card, Container, Heading, Icon, Paragraph } from "@telia-no-min-side/components";
import { getPriceAmountText } from "src/util/priceFormatter";
import { AdditionalInfoContainer, PositivContainer } from "./styles";
import { BillingCycles } from "src/gql/graphql";

type Props = {
  accountId: string;
  distribution: string;
  nextInvoiceDate?: string;
  amount?: number;
  isMobileAccount?: boolean;
  partialPayment?: boolean;
  orderedBillingCycle?: Partial<BillingCycles["orderedBillCycle"]>;
  canChangeBillingCycle?: boolean;
  hasChangedBillingCycle?: boolean;
  currentBillingCycleDueDay?: number | null;
};

export function InvoiceCardPaid({
  amount,
  accountId,
  isMobileAccount,
  distribution,
  nextInvoiceDate,
  partialPayment,
  orderedBillingCycle,
  canChangeBillingCycle,
  hasChangedBillingCycle,
  currentBillingCycleDueDay,
}: Props) {
  return (
    <Card
      padding="lg"
      lozengeRight={{
        label: "Betalt",
        status: "positive",
      }}
    >
      <Container gap flexDirection="column">
        <Heading variant="title-200-light" tag="h3">
          {isMobileAccount ? "Faktura for mobil" : "Faktura for TV/internett"}
        </Heading>
        <PositivContainer flexDirection="row" justifyContent="space-between">
          <Container alignItems="center" gap>
            <Icon icon="check-circle" />
            <Paragraph removeMargin>{partialPayment ? "Penger til gode:" : "Du har betalt det du skal 👏"}</Paragraph>
          </Container>
          {partialPayment && (
            <Container floatContent="right">
              <Paragraph removeMargin variant="preamble-100">
                {getPriceAmountText(amount, "kr")}
              </Paragraph>
            </Container>
          )}
        </PositivContainer>
        <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
          <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
            <Icon size="sm" icon="user" />
            <Paragraph removeMargin isFaded>
              Kundenummer: <em>{accountId}</em>
            </Paragraph>
          </Container>
        </AdditionalInfoContainer>
        <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
          <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
            <Icon size="sm" icon="invoice" />
            <Paragraph removeMargin isFaded>
              Fakturatype: <em>{distribution}</em>
            </Paragraph>
          </Container>
          {isMobileAccount && (
            <Button
              hideLinkIcon
              variant="text-purple"
              size="sm"
              tag="a"
              isInternalNavigation
              href={"/minside/faktura/innstillinger"}
            >
              Endre
            </Button>
          )}
        </AdditionalInfoContainer>
        {isMobileAccount && (
          <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
            <Icon size="sm" icon="calendar" />
            <Paragraph removeMargin isFaded>
              Neste faktura kommer
            </Paragraph>
            <Paragraph isFaded>
              <em>{nextInvoiceDate}</em>
            </Paragraph>
          </AdditionalInfoContainer>
        )}

        {isMobileAccount &&
          (hasChangedBillingCycle ? (
            <AdditionalInfoContainer floatContent="center" flexDirection="column" gap flexGrow={1}>
              <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
                <Icon size="sm" icon="money-back" />
                <Paragraph removeMargin isFaded>
                  Tidligere forfallsdato: <em>{currentBillingCycleDueDay}. hver måned</em>
                </Paragraph>
              </Container>
              <Container flexDirection="row" gap>
                <Icon size="sm" icon="money-back" style={{ visibility: "hidden" }} />
                <Paragraph isFaded>
                  Ny forfallsdato: <em>{orderedBillingCycle?.dueDay}. hver måned</em>, periodestart{" "}
                  <em>{dayjs(orderedBillingCycle?.startDate).format("DD.MMMM")}</em>
                </Paragraph>
              </Container>
            </AdditionalInfoContainer>
          ) : (
            <AdditionalInfoContainer floatContent="center" flexDirection="row" gap flexGrow={1}>
              <Container flexDirection="row" alignItems="center" gap flexGrow={1}>
                <Icon size="sm" icon="money-back" />
                <Paragraph removeMargin isFaded>
                  Forfallsdato: <em>{currentBillingCycleDueDay}. hver måned</em>
                </Paragraph>
              </Container>
              {canChangeBillingCycle && (
                <Button
                  hideLinkIcon
                  variant="text-purple"
                  size="sm"
                  tag="a"
                  isInternalNavigation
                  href={"/minside/faktura/forfallsdato"}
                >
                  Endre
                </Button>
              )}
            </AdditionalInfoContainer>
          ))}
      </Container>
    </Card>
  );
}
