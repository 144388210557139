import { useNavigate } from "react-router-dom";
import {
  Card,
  Container,
  Icon,
  ModalBody,
  Paragraph,
  ProfilePicker,
  useWindowSize,
} from "@telia-no-min-side/components";
import { useState, useRef } from "react";
import { format } from "@telia-no-min-side/utils";
import { CloseButton, SelectSubCard } from "./styles";
import { UserOverviewDataQuery } from "src/gql/graphql";
import { UseMobileAccountSubscriptionData } from "src/hooks/useMobileAccount";
import { Tooltip } from "@telia/teddy";

// TODO: FIX TYPE
function hasBucketDiscount(subscriptions: UseMobileAccountSubscriptionData[]) {
  const subscriptionsInBucketDiscountGroup = (subscriptions || []).filter(
    (subscription) =>
      subscription.userOffering?.additionalProperties?.some(
        (additionalProperty) => additionalProperty?.value === "MHS_B2C"
      )
  );

  return subscriptionsInBucketDiscountGroup.length > 1;
}

type Props = {
  legalOwnerAccounts: UserOverviewDataQuery["accounts"];
};

export function BuyNewSubscriptionCTA({ legalOwnerAccounts }: Props) {
  const [showNewSubscriptionDialog, setShowNewSubscriptionDialog] = useState(false);
  const navigate = useNavigate();
  const { width, isMobile } = useWindowSize();
  const actionCardRef = useRef<HTMLDivElement>(null);
  const validAccounts = legalOwnerAccounts?.filter((account) => !!account?.id);

  if (!validAccounts?.length) {
    return null;
  }

  const enableAccountDialog = (validAccounts || []).length > 1;

  return (
    <>
      <ProfilePicker.ActionCard
        backgroundColor="light-purple"
        tag={enableAccountDialog ? "button" : "a"}
        ref={actionCardRef}
        aria-label="Kjøp nytt abonnement"
        onClick={() => {
          enableAccountDialog
            ? setShowNewSubscriptionDialog((prev) => !prev)
            : navigate(`legg-til/${validAccounts[0]?.id}`);
        }}
      >
        <Icon size="sm" icon="plus" />
        <span>Nytt abonnement</span>
      </ProfilePicker.ActionCard>

      <SelectSubCard
        isOpen={showNewSubscriptionDialog}
        removePadding
        backgroundColor="light-purple"
        setIsOpen={setShowNewSubscriptionDialog}
        style={
          !isMobile
            ? {
                top: actionCardRef.current?.getBoundingClientRect().top,
                right: (width || 0) - (actionCardRef.current?.getBoundingClientRect().right || 0),
              }
            : {}
        }
      >
        <CloseButton
          isIconButton
          icon="close"
          onClick={() => setShowNewSubscriptionDialog(false)}
          title="Lukk"
          size="sm"
          variant="text-black"
        />

        <Paragraph removeMargin>Velg hvilken konto det gjelder:</Paragraph>
        <ModalBody>
          <Container flexDirection="column" gap>
            {validAccounts.map((account) => (
              <div key={`${account.id}-select-account-add-sub`}>
                <Card
                  removeMinHeight
                  data-di-mask
                  tag="a"
                  lozengeRight={
                    hasBucketDiscount(account.subscriptions || [])
                      ? { label: "Med rabatt!", status: "communication" }
                      : undefined
                  }
                  onClick={() => navigate(`legg-til/${account.id}`)}
                >
                  <Container flexDirection="row" gap floatContent="center" alignItems="center">
                    <Icon size="sm" icon="user" />
                    <Paragraph removeMargin>Konto: {account.id}</Paragraph>
                    <Tooltip>
                      <Tooltip.Trigger
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      />
                      <Tooltip.Content
                        side="bottom"
                        style={{ zIndex: 1 }}
                        onPointerDownOutside={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div>Abonnement på denne kontoen:</div>
                        <ul>
                          {(account.subscriptions || []).map((subscription) => (
                            <li key={subscription.phoneNumber.localNumber}>{`${format.startCase(
                              subscription?.productUser?.firstName || ""
                            )} (${format.formatPhoneNumber(subscription.phoneNumber.localNumber)})`}</li>
                          ))}
                        </ul>
                      </Tooltip.Content>
                    </Tooltip>
                  </Container>
                </Card>
              </div>
            ))}
          </Container>
        </ModalBody>
      </SelectSubCard>
    </>
  );
}
