import { ACCESS_LEVEL, InformationItem, useAuth } from "@telia-no-min-side/components";
import { format, uri } from "@telia-no-min-side/utils";
import { AgreementType, BillingType, SubscriptionStatus, UserRole } from "src/gql/graphql";
import { useMobileAccount } from "src/hooks/useMobileAccount";
import { committedEndDate } from "src/util/format-subscription/isCommitted";
import { L } from "src/util/links";
import { getName } from "src/util/format-subscription/getName";
import { getConvergentBenefitAmount } from "src/util/format-subscription/getConvergentBenefitAmount";
import { useQuery } from "@apollo/client";
import { GET_SUBSCRIPTION_INFO } from "../graphql/getSubscriptionInfo";
import { getLegalOwnerName } from "src/util/format-subscription/getLegalOwnerName";
import { InformationSectionContainer } from "../style";
import { isJuniorSummerCampaign } from "util/format-subscription/isJuniorSummerCampaign";
import { getUserOfferingWithAnnualDiscount } from "util/mobile/offer";

export const ACCOUNT_TYPE_KEY_PARAM = "accT";
export const BAN_KEY_PARAM = "ban";
export const PHONE_NUMBER_KEY_PARAM = "pn";

export const InformationSectionMobile = () => {
  const { accessLevel } = useAuth();
  const { phoneNumber } = useMobileAccount();

  const { loading, data } = useQuery(GET_SUBSCRIPTION_INFO, { variables: { phoneNumber } });

  const accountId = data?.subscription?.account?.id;
  const subscription = data?.subscription;
  const offeringPrices = subscription?.userOffering?.offeringPrices?.find(
    (offeringPrice) => offeringPrice?.offeringPrices
  )?.offeringPrices;
  const convergentBenefitAmount = getConvergentBenefitAmount(subscription?.additionalProducts || []);
  const subscriptionName = getName(
    offeringPrices,
    subscription?.userOffering?.shortName,
    convergentBenefitAmount.hasDoubleData
  );
  const subscriptionPrice = subscription?.userOffering?.monthlyCost?.amount;
  const hasJuniorSummerCampaign = isJuniorSummerCampaign(subscription?.userOffering?.pricePlan || "");

  const isLegalOwner = subscription?.roles.includes(UserRole.LegalOwner);
  const isB2B = subscription?.agreementType === AgreementType.Business;
  const isB2BProductUser = isB2B && !isLegalOwner;

  const isSubscriptionSuspended =
    subscription?.subscriptionStatus === SubscriptionStatus.Suspended && !!subscription.productUser;

  const isPrepaid = subscription?.billingType === BillingType.Prepaid;
  const { commitmentEndDate = null } = subscription ? subscription : {};

  const canUpgradeToCommitment =
    !commitmentEndDate &&
    isLegalOwner &&
    subscription?.agreementType === AgreementType.Private &&
    !!getUserOfferingWithAnnualDiscount(
      subscription.userOffering?.shortName || "",
      subscription.newOfferingDetails?.offerings
    );

  const canChangeSubscription =
    (!isB2B || isLegalOwner) && !isSubscriptionSuspended && accessLevel === ACCESS_LEVEL.SILVER;

  return (
    <InformationSectionContainer
      padding="vertical"
      flexDirection="row"
      flexWrap
      data-tracking-id="main-layout-top-section"
    >
      <InformationItem
        fitContent
        title="Abonnement"
        text={subscriptionName || ""}
        isLoading={loading}
        actionLinks={
          canChangeSubscription
            ? [
                {
                  children: "Endre",
                  href: uri.minSideFixed(
                    isPrepaid ? `/mobil/legg-til/${accountId}` : `/mobil/endre/${accountId}/${phoneNumber}`
                  ),
                  isInternalNavigation: true,
                },
              ]
            : []
        }
      />

      {!!subscriptionPrice && !isB2BProductUser && (
        <InformationItem
          fitContent
          title={hasJuniorSummerCampaign ? "Kampanjepris" : "Pris"}
          text={`${subscriptionPrice || ""},-/md.`}
          isLoading={loading}
          actionLinks={[
            {
              children: "Faktura",
              href: uri.minSideFixed(
                `/faktura?${BAN_KEY_PARAM}=${accountId}&${PHONE_NUMBER_KEY_PARAM}=${phoneNumber}&${ACCOUNT_TYPE_KEY_PARAM}=MOBILE`
              ),

              isInternalNavigation: true,
            },
          ]}
        />
      )}

      {!isPrepaid && accountId && (
        <InformationItem
          fitContent
          data-hj-suppress
          title="Juridisk eier"
          text={format.startCase(getLegalOwnerName(subscription?.legalOwner))}
          isLoading={loading}
        />
      )}

      <InformationItem
        fitContent
        data-hj-suppress
        title="Bindingstid"
        text={committedEndDate(commitmentEndDate) || "Ingen"}
        isLoading={loading}
        actionLinks={
          canUpgradeToCommitment
            ? [
                {
                  children: "Få årsrabatt",
                  href: uri.minSideMobile(`/bestill-arsrabatt/${phoneNumber}`),
                  isInternalNavigation: true,
                },
              ]
            : undefined
        }
      />

      {isLegalOwner && (
        <InformationItem
          fitContent
          title={"Avtale"}
          text=""
          isLoading={loading}
          actionLinks={[
            {
              children: L.AccountLevelAgreements.text,
              href: L.AccountLevelAgreements.href,
              isInternalNavigation: L.AccountLevelAgreements.internalLink,
            },
          ]}
        />
      )}
    </InformationSectionContainer>
  );
};
