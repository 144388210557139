import { useMBBSubscriptionData } from "pages/broadbandNew/hooks/useMBBSubscriptionData";
import { Drawer } from "@telia/teddy";
import { HvilemodusDrawerContent } from "./HvilemodusDrawerContent";
import { RLHDrawerContent } from "./RLHDrawerContent";
import { TopupDrawerContent } from "./TopupDrawerContent";

export function MBBDrawer() {
  const { isDrawerOpen, closeDrawer, drawerContent, setDrawerContent, setTopupCategory } = useMBBSubscriptionData();
  return (
    <Drawer
      open={isDrawerOpen}
      onOpenChange={(open) => {
        !open && closeDrawer();
        setDrawerContent(undefined);
        setTopupCategory(undefined);
      }}
    >
      <Drawer.Content width="100%" maxWidth="500px">
        <Drawer.Close aria-label="Close" slot="floating" onClick={closeDrawer} />
        {drawerContent === "Hvilemodus" && <HvilemodusDrawerContent />}
        {drawerContent === "RLH" && <RLHDrawerContent />}
        {drawerContent === "Topup" && <TopupDrawerContent />}
      </Drawer.Content>
    </Drawer>
  );
}
