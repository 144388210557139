export function getTabRoutes(isPrepaid: boolean, notSupportedSubscription: boolean, showNewPrepaidPage?: boolean) {
  if (isPrepaid) {
    if (showNewPrepaidPage) {
      return [
        { tabName: "Forbruk", link: "forbruk" },
        { tabName: "SIM/PIN/PUK", link: "sim-pin-puk" },
        { tabName: "Innstillinger", link: "innstillinger" },
        { tabName: "Kjøpshistorikk", link: "kjopshistorikk" },
      ];
    }
    return [
      { tabName: "Saldo", link: "saldo" },
      { tabName: "SIM/PIN/PUK", link: "sim-pin-puk" },
      { tabName: "Innstillinger", link: "innstillinger" },
    ];
  }

  if (notSupportedSubscription) {
    return [
      { tabName: "Info", link: "info" },
      { tabName: "Innstillinger", link: "innstillinger" },
    ];
  }

  return [
    { tabName: "Forbruk", link: "forbruk" },
    { tabName: "SIM/PIN/PUK", link: "sim-pin-puk" },
    { tabName: "Innstillinger", link: "innstillinger" },
  ];
}
