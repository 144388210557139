import { Link } from "react-router-dom";
import { Flex, Button, Icon, Text, IconName, Skeleton } from "@telia/teddy";

export type Props = {
  buttonsData: { URL: string; text: string; icon: IconName; hide?: boolean; isLoading?: boolean }[];
};

export function NavigationButtons({ buttonsData }: Props) {
  const visibleButtons = buttonsData.filter(({ hide, isLoading }) => (isLoading ? true : !hide));

  return (
    <Flex gap="gutter-md">
      {visibleButtons.map((button) => (
        <Flex key={button.text} direction="column" gap="100" align="center">
          <Flex>
            <Skeleton loading={!!button.isLoading} radius="full">
              <Button aria-label={button.icon} iconOnly variant="primary" asChild>
                <Link to={button.URL}>
                  <Icon name={button.icon} />
                </Link>
              </Button>
            </Skeleton>
          </Flex>
          <Skeleton loading={!!button.isLoading}>
            <Text>{button.text}</Text>
          </Skeleton>
        </Flex>
      ))}
    </Flex>
  );
}
