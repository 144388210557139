import { Box, Icon, Text, Flex, Image } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { ExpandableProductCard } from "components/expandable-product-card";
import { getSimplePriceText } from "util/priceFormatter";

type Props = {
  flexCombo?: types.fixed.FlexCurrent;
  isLoading: boolean;
  isSafeOnNetBenefitVisible?: boolean;
  actions: types.fixed.Product["actions"];
};

export function FlexInfoCard({ flexCombo, isLoading, isSafeOnNetBenefitVisible, actions }: Props) {
  const tvOption = flexCombo?.currentTvAndBroadband?.tvOption;
  const broadbandOption = flexCombo?.currentTvAndBroadband?.broadbandOption;
  const sum = flexCombo?.currentTvAndBroadband?.sum;

  return (
    <ExpandableProductCard
      category={tvOption && broadbandOption ? "Internett + Strømming & TV" : ""}
      name={flexCombo?.productTitle || ""}
      price={getSimplePriceText(sum?.currentAmount, sum?.chargePeriod)}
      actions={actions}
      expandableContent={
        <>
          <Flex direction="column" gap="150">
            <Text as="p" variant="paragraph-100-bold">
              Inkludert
            </Text>
            {broadbandOption?.downloadSpeed && broadbandOption?.downloadSpeed > 0 && (
              <Flex mt="200" gap="200">
                <Icon name="speedometer" size="md" />
                <Text>{broadbandOption?.salesText}</Text>
              </Flex>
            )}
            {isSafeOnNetBenefitVisible && (
              <Flex mt="200" gap="200">
                <Icon name="secured-1" size="sm" />
                <Text variant="paragraph-100">Trygg på Nett-pakken - digital sikkerhet for hele familien</Text>
              </Flex>
            )}
            {tvOption?.points && tvOption?.points > 0 && (
              <Flex mt="200" gap="200">
                <Box mb="auto">
                  <Icon name="entertainment" size="md" />
                </Box>
                <Box>
                  <Text>{tvOption.salesText}</Text>
                  {tvOption.logos.length > 0 && (
                    <Flex mt="200" gap="300">
                      {tvOption.logos.map((logo) => (
                        <Box key={logo.name} flexBasis="0" flexGrow="1" maxWidth="60px" minWidth="0">
                          <Image alt={logo.name} src={logo.src} />
                        </Box>
                      ))}
                    </Flex>
                  )}
                </Box>
              </Flex>
            )}
          </Flex>
        </>
      }
      footerContent={
        <Flex gap="200">
          {broadbandOption?.downloadSpeed && broadbandOption?.downloadSpeed > 0 && (
            <Icon name="speedometer" size="md" />
          )}
          {tvOption?.points && tvOption?.points > 0 && <Icon name="entertainment" size="md" />}
        </Flex>
      }
      isLoading={isLoading}
    />
  );
}
