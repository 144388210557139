import { Icon } from "@telia/teddy";
import { useAuth } from "@telia-no-min-side/components";
import { useSelectAccount } from "hooks/useSelectAccount";
import { useFetchBroadbandSubscription } from "src/api/fixed";
import { AUTH } from "util/constants/auth";
import { Breadcrumb } from "components/Breadcrumb";
import { ErrorBox } from "components/ErrorBox";
import { SubscriptionInfo } from "./SubscriptionInfo";
import { Navigation } from "./Navigation";
import { Hardware } from "./Hardware";
import { BuyHardware } from "./BuyHardware";
import { AdditionalInfo } from "./AdditionalInfo";
import { NoBroadband } from "./NoBroadBand";
import { useBroadbandProfiles } from "../hooks/useBroadbandProfiles";
import { GetAccessFixedCard } from "components/get-access-fixed-card";

export function BroadbandDashboard() {
  const { selectedAuthProvider } = useAuth();
  const { selectedAccountType } = useSelectAccount();
  const broadbandSubscription = useFetchBroadbandSubscription();
  const broadbandProfiles = useBroadbandProfiles();
  const hasBroadbandSubscription =
    broadbandSubscription.data?.products && broadbandSubscription.data.products.length > 0;

  if (!broadbandProfiles.loading && broadbandProfiles.profiles.length === 0) {
    return (
      <>
        <Breadcrumb />
        <NoBroadband />
      </>
    );
  }

  return (
    <>
      <Breadcrumb />
      {selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED && selectedAuthProvider === AUTH.PROVIDER.CT ? (
        <GetAccessFixedCard />
      ) : selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED && broadbandSubscription.isError ? (
        <ErrorBox
          title="Vi beklager"
          description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
          actionContent={
            <>
              <Icon name="sync" /> Last side på nytt
            </>
          }
          onClick={() => broadbandSubscription.mutate()}
          width={{ md: "100%", lg: "40%" }}
        />
      ) : selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED &&
        !hasBroadbandSubscription &&
        !broadbandSubscription.isLoading ? (
        <NoBroadband />
      ) : (
        <>
          <SubscriptionInfo />
          <Navigation />
          <Hardware />
          <BuyHardware />
        </>
      )}
      <AdditionalInfo />
    </>
  );
}
