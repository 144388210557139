import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uri } from "@telia-no-min-side/utils";
import { MinSide } from "..";
import { Backdrop } from "../../Backdrop";
import { useWindowSize } from "../../hooks";
import { Button } from "../../Button";
import { Container } from "../../Container";
import { DropDown, LinkSection, MinSideButtonWrapper, TopSection } from "./style";
import { BusinessLinks } from "./BusinessLinks";
import { CustomerLinks } from "./CustomerLinks";
import { Drawer, Tabs, TextSpacing, Heading, Icon, Button as TeddyButton, Flex } from "@telia/teddy";

type DropDownSelector = "b2c" | "b2b";

function getButtonStyle(isSelected: boolean): "tertiary-purple" | "tertiary-black" {
  if (isSelected) {
    return "tertiary-purple";
  }
  return "tertiary-black";
}

export type MinSideButtonProps = {
  minSide: MinSide;
  limitedAccess: boolean;
  // TODO: Remove after new top menu release
  isNewProductPageEnabled?: boolean;
  showNewSideMenu?: boolean;
};

export function MinSideButton(props: MinSideButtonProps) {
  const { minSide, limitedAccess, isNewProductPageEnabled, showNewSideMenu } = props;
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dropDownPage, setDropDownPage] = useState<DropDownSelector>("b2c");

  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const loggout = () => {
    const href = minSide === "fixed" ? uri.minSideFixed("/logg-ut") : uri.minSideMobile("/logg-ut");
    navigate(href);
  };

  return (
    <MinSideButtonWrapper>
      {isMobile ? (
        <Container floatContent="center" flexDirection="row" gap>
          <Button
            icon="home"
            iconSize="md"
            tag="a"
            isInternalNavigation={minSide === "fixed"}
            href={uri.minSideFixed("/hjem")}
            title="hjem"
            size="sm"
            isIconButton
            trackEvent={{
              ui_item_action: "link_click",
              ui_item_context: "main-layout",
              ui_item_text: "hjem",
              ui_item_type: "link",
              ui_item_url: uri.minSideFixed("/hjem"),
            }}
            variant="tertiary-black"
          />
          <Button
            title="Mine Sider"
            size="sm"
            isIconButton
            iconSize="md"
            variant="tertiary-black"
            icon="user"
            onClick={() => setMenuOpen((prevState) => !prevState)}
          />
        </Container>
      ) : !isNewProductPageEnabled ? (
        <Button
          onClick={() => setMenuOpen((prevState) => !prevState)}
          variant="expressive"
          size="sm"
          icon="user"
          data-tracking-id="minside-button-navigation"
        >
          Mine Sider
        </Button>
      ) : null}

      {showNewSideMenu ? (
        <Drawer open={isMenuOpen} onOpenChange={(open) => setMenuOpen(open)}>
          <Drawer.Content asChild>
            <Drawer.Close aria-label="Close" slot="floating" />
            <Drawer.Title mt="200">Dine sider</Drawer.Title>
            <Tabs defaultValue="private">
              <Tabs.List>
                <Tabs.Trigger value="private">Privat</Tabs.Trigger>
                <Tabs.Trigger value="business">Bedrift</Tabs.Trigger>
              </Tabs.List>
              <TextSpacing>
                <Tabs.Content value="private">
                  <Flex direction="column" gap="200">
                    <Heading variant="title-100" as="h3" mb="100" mt="400">
                      Min Side
                    </Heading>
                    <CustomerLinks
                      onSelect={() => setMenuOpen((prev) => !prev)}
                      minSide={minSide}
                      limitedAccess={limitedAccess}
                    />
                    <div>
                      <TeddyButton mt="200" onClick={loggout} variant="secondary" fullWidth={false}>
                        <Icon name="logout">Logg ut</Icon>
                      </TeddyButton>
                    </div>
                  </Flex>
                </Tabs.Content>
                <Tabs.Content value="business">
                  <Flex direction="column" gap="200">
                    <Heading variant="title-100" as="h3" mb="100" mt="400">
                      Bedriftsportaler
                    </Heading>
                    <BusinessLinks />
                  </Flex>
                </Tabs.Content>
              </TextSpacing>
            </Tabs>
            <Drawer.Footer>
              <Drawer.Close slot="content">
                <TeddyButton variant="secondary">Lukk</TeddyButton>
              </Drawer.Close>
            </Drawer.Footer>
          </Drawer.Content>
        </Drawer>
      ) : null}

      {!showNewSideMenu && isMenuOpen && (
        <>
          <Backdrop isVisible={isMenuOpen} color="transparent" onClick={() => setMenuOpen(false)} zIndex={0} />

          <DropDown>
            <TopSection data-tracking-id="minside-button-private-business-layout">
              <Button size="sm" onClick={() => setDropDownPage("b2c")} variant={getButtonStyle(dropDownPage === "b2c")}>
                Privat
              </Button>

              <Button size="sm" variant={getButtonStyle(dropDownPage === "b2b")} onClick={() => setDropDownPage("b2b")}>
                Bedrift
              </Button>
            </TopSection>

            <LinkSection>
              {dropDownPage === "b2c" ? (
                <>
                  <CustomerLinks
                    onSelect={() => setMenuOpen((prev) => !prev)}
                    minSide={minSide}
                    limitedAccess={limitedAccess}
                  />
                  <Button
                    tag="a"
                    id="min-side-logout-button"
                    icon="logout"
                    variant="secondary"
                    href={minSide === "fixed" ? uri.minSideFixed("/logg-ut") : uri.minSideMobile("/logg-ut")}
                    isInternalNavigation
                  >
                    Logg ut
                  </Button>
                </>
              ) : (
                <BusinessLinks />
              )}
            </LinkSection>
          </DropDown>
        </>
      )}
    </MinSideButtonWrapper>
  );
}
