import { Container, devices } from "@telia-no-min-side/components";
import styled from "styled-components";

export const NotificationHeading = styled.h2`
  font-size: 1rem;
  margin: 0;
`;

export const StyledContainer = styled(Container)`
  padding: 1rem;
  @media ${devices.md_down} {
    padding: 1rem 0.5rem;
  }
`;
