import { track, types } from "@telia-no-min-side/utils";
import { getChargePeriodMultiplier } from "src/util/prices";
import { v4 } from "uuid";
import { getProductShipping } from "./shipping";

type Args = {
  selectedRouter: types.fixed.ProductGroupSingleProductWithShipping | undefined;
  smartExtender: types.fixed.ProductGroupSingleProductWithShipping | undefined;
  quantityExtender: number;
  shippingMethodId?: number;
};

export function getOrderToPlace({
  selectedRouter,
  smartExtender,
  quantityExtender,
  shippingMethodId,
}: Args): types.fixed.SubmitMultipleProducts {
  const routerOrder = selectedRouter
    ? [
        {
          axiomId: selectedRouter.axiomId,
          quantity: 1,
          addonIds: [],
        },
      ]
    : [];
  const extenderOrder =
    smartExtender && quantityExtender
      ? [
          {
            axiomId: smartExtender.axiomId,
            quantity: quantityExtender,
            addonIds: [],
          },
        ]
      : [];

  return {
    axiomIds: [...routerOrder, ...extenderOrder],
    shippingMethodId,
  };
}

export function getTransactionTracking({
  selectedRouter,
  smartExtender,
  quantityExtender,
}: Omit<Args, "shippingMethodId">): track.Transaction | undefined {
  if (!selectedRouter && !smartExtender) {
    return;
  }
  const shipping = getProductShipping(selectedRouter?.shippingOptionDTOS, smartExtender?.shippingOptionDTOS);
  const recurringPriceRouter = selectedRouter?.price.currentPrice.recurring || 0;
  const recurringPriceExtender = quantityExtender * (smartExtender?.price.currentPrice.recurring || 0);
  const recurringPrice = recurringPriceRouter + recurringPriceExtender;
  const yearMultiplierRouter = selectedRouter
    ? getChargePeriodMultiplier("år", selectedRouter.price.currentPrice.chargePeriod)
    : 0;
  const yearMultiplierExtender = smartExtender
    ? getChargePeriodMultiplier("år", smartExtender.price.currentPrice.chargePeriod)
    : 0;
  const yearMultiplier = yearMultiplierRouter || yearMultiplierExtender || 0;
  const yearlyCost =
    recurringPrice * yearMultiplier +
    (selectedRouter?.price.currentPrice.onceOff || 0) +
    (smartExtender?.price.currentPrice.onceOff || 0);
  const shippingPrice = shipping?.onceOffPrice || 0;

  const combosaleProductname =
    selectedRouter && smartExtender && quantityExtender
      ? `${selectedRouter.name},  ${quantityExtender} x ${smartExtender.name}`
      : undefined;

  return {
    shippingPrice,
    transactionId: v4(),
    revenue: yearlyCost + shippingPrice,
    products: [
      ...(shipping
        ? [
            {
              brand: "Telia",
              category: "Service/Delivery",
              id: "DELIVERY",
              name: shipping.description || "Frakt",
              price: shippingPrice,
              quantity: 1,
              financing: "Billing",
              delivery: "Posten",
            } as const,
          ]
        : []),
      ...(selectedRouter
        ? [
            {
              brand: "Telia",
              category: "Hardware/Router/Fixed",
              id: `${selectedRouter.productId}`,
              name: selectedRouter.name || "unknown",
              price: recurringPriceRouter * yearMultiplierRouter + selectedRouter.price.currentPrice.onceOff,
              quantity: 1,
              financing: "billing",
              acquisitionRetention: "Acquisition",
              newPortinUpsell: "New",
              combosaleProductname,
            } satisfies track.TransactionProduct,
          ]
        : []),
      ...(smartExtender && quantityExtender
        ? [
            {
              brand: "Telia",
              category: "Hardware/Router/Extender",
              id: `${smartExtender.productId}`,
              name: smartExtender.name || "unknown",
              price: recurringPriceExtender * yearMultiplierExtender + smartExtender.price.currentPrice.onceOff,
              quantity: quantityExtender,
              financing: "billing",
              acquisitionRetention: "Acquisition",
              newPortinUpsell: "New",
              combosaleProductname,
            } satisfies track.TransactionProduct,
          ]
        : []),
    ],
  };
}
