import { Button, Paragraph } from "@telia-no-min-side/components";
import { uri } from "@telia-no-min-side/utils";
import { TRACK_EVENT } from "util/constants/googleAnalytics";
import { config } from "util/config";
export function ShowLinkToMinsideTv() {
  return (
    <div>
      <Paragraph>
        <Button
          tag="a"
          href={uri.minSideFixed(config.isNewProductPageEnabled ? "/tv" : "/tv/tv-innhold")}
          trackEvent={{
            ui_item_action: TRACK_EVENT.ITEM_ACTION.LINK_CLICK,
            ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
            ui_item_text: TRACK_EVENT.ITEM_TEXT.GO_TO_MINSIDE_TV,
            ui_item_type: TRACK_EVENT.ITEM_TYPE.LINK,
            ui_item_url: uri.minSideFixed(config.isNewProductPageEnabled ? "/tv" : "/tv/tv-innhold"),
          }}
          isInternalNavigation
        >
          Gå til Minside TV
        </Button>
      </Paragraph>
    </div>
  );
}
