import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@telia/styleguide";
import { devices, Container } from "@telia-no-min-side/components";

export const TabLink = styled(NavLink)`
  font-weight: 400;
  color: ${colors.grey800};
  font-size: 1.125rem;
  height: 1.8rem;
  list-style: none;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: block;
  padding: 0;
  /* If desktop  */
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${colors.corePurple800};
      background-color: ${colors.corePurple100};
    }
  }

  &.active {
    color: ${colors.corePurple700};
    border-bottom: 2px solid ${colors.corePurple700};
  }
`;

export const InformationSectionContainer = styled(Container)`
  padding-left: 1rem;
  @media ${devices.sm_down} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    gap: 0.5rem;
    padding-left: 0rem;
    width: 100%;
  }
`;
