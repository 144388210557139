import { Card, Flex, Text, Box, Skeleton } from "@telia/teddy";
import { format } from "@telia-no-min-side/utils";
import { TopupOrderHistory } from "gql/graphql";
import { getPriceAmountText } from "util/priceFormatter";

export type Props = {
  orders: TopupOrderHistory[];
};

function Loader() {
  return (
    <Card bordered shadow key="loading" justify="stretch">
      <Flex justify="between" mt="100">
        <Box>
          <Skeleton width="200px" height="15px" />
          <Skeleton width="100px" height="15px" mt="200" />
        </Box>
        <Skeleton width="75px" height="15px" />
      </Flex>
    </Card>
  );
}

export function OrderList({ orders }: Props) {
  return (
    <>
      {orders?.map(
        (order) =>
          order?.orderId && (
            <Card bordered shadow key={order.orderId} justify="stretch" mb="200">
              <Flex justify="between">
                <Box>
                  <Text>{order.product?.name}</Text>
                  <Text variant="additional-100">{format.formatDate(order.date, "DD.MM.YY")}</Text>
                </Box>
                <Text>{getPriceAmountText(order.payment?.amount?.amount)}</Text>
              </Flex>
            </Card>
          )
      )}
    </>
  );
}

OrderList.Loader = Loader;
