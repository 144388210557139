import { Link } from "react-router-dom";
import { Button, ExpandableCard, Flex, Heading, Icon, List, Text } from "@telia/teddy";
import { IncludedProperty } from "./HvilemodusDrawerContent";

type Props = {
  includedProperties: IncludedProperty[];
  changeSubscriptionLink: string;
};

export const CardContentMBB = ({
  includedProperties,
  changeSubscriptionLink,
}: Pick<Props, "includedProperties" | "changeSubscriptionLink">) => {
  return (
    <List isSubcomponent layout="horizontal">
      <Flex width="100%" justify="between" align="center">
        <Flex gap="100">
          {includedProperties
            .filter((properties) => properties.icon)
            .map(({ icon, code }) => (
              <List.Item key={`subscription-card-content-property-${code}`}>
                <List.Illustration>
                  <Flex align="center" gap="25">
                    <Icon name={icon} size="md" />
                    <Text>{code === "EXTRA_SIM" && "x2"}</Text>
                  </Flex>
                </List.Illustration>
              </List.Item>
            ))}
        </Flex>
        <Button variant="text" asChild>
          <Link to={changeSubscriptionLink}>Endre</Link>
        </Button>
      </Flex>
    </List>
  );
};

export const CardExpandedContentMBB = ({
  includedProperties,
  changeSubscriptionLink,
}: Pick<Props, "includedProperties" | "changeSubscriptionLink">) => {
  return (
    <ExpandableCard.Content>
      <Heading as="h4" variant="title-100">
        Inkludert
      </Heading>
      <List isSubcomponent layout="vertical">
        {includedProperties.map(({ icon, code, text }) => (
          <List.Item key={`subscription-card-expanded-content-property-${code}`}>
            {icon && (
              <List.Illustration>
                <Icon name={icon} size="md" />
              </List.Illustration>
            )}
            <List.Content>{text}</List.Content>
          </List.Item>
        ))}
      </List>

      <Flex width="100%" justify="end">
        <Button variant="text" asChild>
          <Link to={changeSubscriptionLink}>Endre</Link>
        </Button>
      </Flex>
    </ExpandableCard.Content>
  );
};
