import { useMBBSubscriptionData } from "pages/broadbandNew/hooks/useMBBSubscriptionData";
import { useNavigate } from "react-router-dom";
import { Flex, Card, Button, Icon, FlipCard, Accordion, Text, MeterBar, Notification, Box, color } from "@telia/teddy";
import { formatDataAmount } from "@telia-no-min-side/utils";
import { ActionsSection } from "..";
import { getDataUsageDetails, getTotalDataUsage, mergeDataUsageByType } from "../../utils";
import { useMobileAccount } from "hooks/useMobileAccount";
import { CoverageGuaranteeTerms } from "./CoverageGuaranteeTerms";
import { UsageDataType } from "../../utils/mergeDataUsageByType";

const TEN_GIGABYTES = 10 * 1024 * 1024 * 1024;

/**
 * Calculate total data usage with coverage guarantee
 * This adds 10GB to both the limit and remaining data
 */
function getTotalDataUsageCoverageGuarantee(groupedData: UsageDataType[]) {
  const { limit, remaining } = getTotalDataUsage(groupedData);
  const usedData = limit - remaining;
  if (usedData >= TEN_GIGABYTES) {
    return { limit, remaining };
  }
  const totalLimit = limit + TEN_GIGABYTES;
  const totalRemaining = remaining + TEN_GIGABYTES;

  return { limit: totalLimit, remaining: totalRemaining };
}

/**
 * Process data usage details with coverage guarantee business logic:
 * - 10GB coverage guarantee is used first
 * - Base product is only used after coverage guarantee is depleted
 * - If coverage guarantee not fully used, base product remains untouched (100%)
 */
function getDataUsageDetailsCoverageGuarantee(
  groupedData: UsageDataType[],
  totalUsageLimit: number,
  isUnlimitedDataSubscription: boolean
): ReturnType<typeof getDataUsageDetails> {
  // Get base data usage info
  const { remaining, limit } = getTotalDataUsage(groupedData);
  const usedData = limit - remaining;

  // Coverage guarantee calculations
  const usedCoverageGuarantee = Math.min(usedData, TEN_GIGABYTES);
  const remainingCoverageGuarantee = TEN_GIGABYTES - usedCoverageGuarantee;
  const remainingCoverageGuaranteePercentage = Math.round((remainingCoverageGuarantee / TEN_GIGABYTES) * 100);
  const isCoverageGuaranteeActive = remainingCoverageGuarantee > 0;

  // Base product is untouched if coverage guarantee still has data
  const baseRemaining = isCoverageGuaranteeActive ? limit : remaining;
  const basePercentage = isCoverageGuaranteeActive ? 100 : Math.round((remaining / limit) * 100);

  // Process all data details, with special handling for BASE type
  const processedDetails = getDataUsageDetails(groupedData, totalUsageLimit).map((detail) => {
    if (detail.type === "BASE" && isUnlimitedDataSubscription) {
      return {
        ...detail,
        remaining: baseRemaining + TEN_GIGABYTES,
        remainingFormatted: formatDataAmount(baseRemaining + TEN_GIGABYTES),
        percentage: 100,
      };
    }
    if (detail.type === "BASE") {
      return {
        ...detail,
        remaining: baseRemaining,
        remainingFormatted: formatDataAmount(baseRemaining),
        percentage: basePercentage,
      };
    }
    return detail;
  });

  // Add coverage guarantee as a separate entry
  const coverageGuaranteeDetail = {
    color: color.teddyColorBlue200,
    title: "Dekningsgaranti",
    remaining: remainingCoverageGuarantee,
    remainingFormatted: formatDataAmount(remainingCoverageGuarantee),
    limitFormatted: formatDataAmount(TEN_GIGABYTES),
    percentage: remainingCoverageGuaranteePercentage,
    limit: TEN_GIGABYTES,
    type: "COVERAGE_GUARANTEE",
  };

  return [...processedDetails, coverageGuaranteeDetail];
}

export function CoverageGuaranteeCard() {
  const { phoneNumber, accountId } = useMobileAccount();
  const { domesticalUsage, isUnlimitedDataSubscription, timeUntilDataUsagePeriodStarts, isSleepMode } =
    useMBBSubscriptionData();
  const navigate = useNavigate();

  const groupedData = mergeDataUsageByType(domesticalUsage);
  const totalDataUsage = getTotalDataUsageCoverageGuarantee(groupedData);
  const totalUsedData = totalDataUsage.limit - totalDataUsage.remaining;
  const details = getDataUsageDetailsCoverageGuarantee(groupedData, totalDataUsage.limit, isUnlimitedDataSubscription);

  return (
    <FlipCard>
      <FlipCard.Front bordered shadow layout="default" variant="white" gap="gutter-md">
        <Card.Heading variant="title-400">
          {isUnlimitedDataSubscription
            ? `${formatDataAmount(totalUsedData)} brukt`
            : `${formatDataAmount(totalDataUsage.remaining)} igjen`}
        </Card.Heading>
        <Card.Content>
          <Flex direction="column" gap="gutter-md">
            <Flex direction="column" gap="50">
              <Accordion type="multiple">
                <Accordion.Item value="temp1">
                  <Accordion.Header>
                    <Accordion.Trigger hideIndicator>
                      <Flex direction="column" width="100%">
                        <Flex justify="between">
                          <Text variant="paragraph-100-medium">
                            {isUnlimitedDataSubscription ? "Ubegrenset" : formatDataAmount(totalDataUsage.remaining)}
                          </Text>
                          <Text variant="paragraph-100">
                            {isUnlimitedDataSubscription ? (
                              <Icon name="infinite" size="md" />
                            ) : (
                              formatDataAmount(totalDataUsage.limit)
                            )}
                          </Text>
                        </Flex>

                        <MeterBar max={totalDataUsage.limit}>
                          {details
                            .filter((detail) => detail.percentage)
                            .map((detail) => (
                              <MeterBar.Indicator key={detail.color} color={detail.color} value={detail.remaining} />
                            ))}
                        </MeterBar>
                      </Flex>
                      <Box asChild ml="0" mr="0">
                        <Accordion.Indicator />
                      </Box>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content>
                    <Flex direction="column" gap="100">
                      {details
                        .filter((detail) => {
                          if (isUnlimitedDataSubscription) {
                            return detail.type === "COVERAGE_GUARANTEE";
                          }
                          return detail.percentage;
                        })
                        .map((detail) => (
                          <Flex key={detail.color} direction="column" gap="100">
                            <Flex direction="column">
                              <Flex justify="between">
                                <Text variant="paragraph-100-medium">{detail.remainingFormatted}</Text>
                                <Text variant="paragraph-100">{detail.limitFormatted}</Text>
                              </Flex>
                              <MeterBar max={detail.limit ?? 0}>
                                <MeterBar.Indicator color={detail.color} value={detail.remaining ?? 0} />
                              </MeterBar>
                            </Flex>
                            <Text variant="additional-100">{detail.title}</Text>
                          </Flex>
                        ))}
                    </Flex>
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion>
              {timeUntilDataUsagePeriodStarts && (
                <Text variant="additional-100">Ny periode starter om {timeUntilDataUsagePeriodStarts}</Text>
              )}

              {isSleepMode && (
                <Notification type="tile" variant="information">
                  <Notification.Icon name="coffee" size="md" />
                  <Notification.Text>Hvilemodus er aktivert.</Notification.Text>
                </Notification>
              )}
            </Flex>
            {!isSleepMode && <ActionsSection showTerms />}
          </Flex>
        </Card.Content>

        {isSleepMode && (
          <Card.Footer>
            <Button
              width="100%"
              variant="primary"
              onClick={() => {
                navigate(`/minside/mobilt-bredband/endre/${accountId}/${phoneNumber}`);
              }}
            >
              <Icon name="coffee">Deaktiver Hvilemodus</Icon>
            </Button>
          </Card.Footer>
        )}
      </FlipCard.Front>
      <FlipCard.Back bordered shadow variant="white">
        <CoverageGuaranteeTerms />
      </FlipCard.Back>
    </FlipCard>
  );
}
