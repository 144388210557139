import React from "react";
import { Link, LinkProps as LinkProps } from "../Link";
import { Skeleton } from "../Skeleton";
import { Title, InformationItemContainer, Text, ActonLinks } from "./style";

export type Props = {
  title: string;
  text: string;
  actionLinks?: LinkProps[];
  isLoading?: boolean;
  dataTrackingID?: string;
  fitContent?: boolean;
};

export const InformationItem: React.FC<Props> = (props) => {
  const { title, text, actionLinks, isLoading, dataTrackingID, fitContent } = props;

  return (
    <InformationItemContainer $fitContent={fitContent} data-tracking-id={dataTrackingID}>
      <Title data-di-mask>{isLoading ? <Skeleton variant="text" width="80px" /> : title}</Title>
      <Text data-di-mask>{isLoading ? <Skeleton variant="heading-xs" width="150px" /> : text}</Text>
      {!!actionLinks?.length && !isLoading && (
        <ActonLinks>
          {actionLinks.map((actionLink) => (
            <Link key={`action-link-${actionLink.children}`} {...actionLink} />
          ))}
        </ActonLinks>
      )}
    </InformationItemContainer>
  );
};
