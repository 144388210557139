import React from "react";
import { track, types } from "@telia-no-min-side/utils";
import { Link } from "@telia-no-min-side/components";
import { NotificationData } from ".";

export function getNotificationProductStatusAbused(
  productStatuses?: types.fixed.Product["status"][]
): NotificationData[] {
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const NotificationMessage = (
    <>
      For å verne din sikkerhet har din bredbåndshastighet blitt redusert til 0.25 Mbit/s, grunnet uønsket trafikk fra
      ditt nettverk. For å fjerne denne sperringen, ring kundeservice på tlf:{" "}
      <Link
        onClick={() => {
          pushGenericTrackingEvent({
            ui_item_action: "link_click",
            ui_item_context: "broadband-speed-abused",
            ui_item_text: "Ring +47 924 05 050",
            ui_item_type: "phoneNumber-link",
            ui_item_url: "no_url",
          });
        }}
        variant="standalone"
        href="tel:+4792405050"
        icon="phone"
      >
        +47 924 05 050
      </Link>
    </>
  );
  const productStatusesNotification: NotificationData[] | undefined = productStatuses
    ?.filter((d) => d?.title === "Abuse")
    .map(() => ({
      title: "Redusert bredbåndshastighet",
      type: "broadband-subscription-abused",
      message: NotificationMessage,
    }));
  return productStatusesNotification || [];
}
