import { uri } from "@telia-no-min-side/utils";
import { IconDefinition } from "@telia/styleguide";
import { MinSide } from "..";
function stripQueryStringAndHashFromPath(path: string): string {
  return path.split("?")[0].split("#")[0];
}

export function isLinkActive(currentPathName: string, href: string, isExactPath?: boolean): boolean {
  const stripedPath = stripQueryStringAndHashFromPath(href);
  if (isExactPath) {
    return currentPathName === href;
  }
  return currentPathName.startsWith(stripedPath);
}

export type MenuLinkId =
  | "menu_link_home"
  | "menu_link_more"
  | "menu_link_mobile"
  | "menu_link_tv"
  | "menu_link_internet"
  | "menu_link_invoice"
  | "menu_link_invoice_mobile"
  | "menu_link_invoice_fixed"
  | "menu_link_alerts"
  | "menu_link_alerts_mobile"
  | "menu_link_alerts_fixed"
  | "menu_link_profile"
  | "menu_link_profile_mobile"
  | "menu_link_profile_fixed"
  | "menu_link_customer_care"
  | "menu_link_shopping"
  | "menu_link_shopping_mobile"
  | "menu_link_shopping_smart-watch"
  | "menu_link_shopping_pakke_tilbud"
  | "menu_link_shopping_tablet"
  | "menu_link_shopping_addon"
  | "menu_link_mobiltbredband"
  | "menu_link_personvern"
  | "menu_link_customer_vas"
  | "menu_link_customer_vas_mobile"
  | "menu_link_customer_vas_tv"
  | "menu_link_customer_vas_internet";

export type MenuLinkGroup = {
  type: "MenuLinkGroup";
  id: MenuLinkId;
  label: string;
  icon: IconDefinition;
  items: MenuLink[];
};

export type MenuLink = {
  type: "MenuLink";
  id: MenuLinkId;
  label: string;
  href: string;
  isInternalLink: boolean;
  hasNotification?: boolean;
  icon?: IconDefinition;
  isExactPath?: boolean;
  limitedAccessView?: boolean;
};

export type MenuLinks = (MenuLink | MenuLinkGroup)[];

type ActiveRouts = Record<MenuLinkId, RegExp>;

export const regExpsForCheckingActiveRoutes: Omit<
  ActiveRouts,
  | "menu_link_alerts"
  | "menu_link_more"
  | "menu_link_shopping"
  | "menu_link_shopping_mobile"
  | "menu_link_shopping_smart-watch"
  | "menu_link_shopping_pakke_tilbud"
  | "menu_link_shopping_tablet"
  | "menu_link_shopping_addon"
  | "menu_link_customer_vas"
> = {
  menu_link_mobile: RegExp("min-side(?!/.*(faktura|mobilt-bredband|profil|personvern|varsler))|minside/mobil/*"),
  menu_link_tv: RegExp("minside/tv(?!/.*faktura)"),
  menu_link_internet: RegExp("minside/(?=(?:internett|router-innstillinger|wifi-nettverk))(?!/.*faktura)"),
  menu_link_invoice: RegExp("minside/(?=.*faktura)"),
  menu_link_invoice_fixed: RegExp("minside/(?=.*faktura)"),
  menu_link_invoice_mobile: RegExp("min-side/(?=.*faktura)"),
  menu_link_personvern: RegExp("(minside|min-side)/(?=.*personvern)"),
  menu_link_profile: RegExp("minside/(?=.*profil)"),
  menu_link_profile_fixed: RegExp("minside/(?=.*profil)"),
  menu_link_profile_mobile: RegExp("min-side/(?=.*profil)"),
  menu_link_alerts_fixed: RegExp("minside/varsler"),
  menu_link_home: RegExp("minside/hjem"),
  menu_link_alerts_mobile: RegExp("min-side/varsler"),
  menu_link_mobiltbredband: RegExp("min-side/mobilt-bredband"),
  menu_link_customer_care: RegExp("minside/hjelp"),
  menu_link_customer_vas_mobile: RegExp("minside/tjenester"),
  menu_link_customer_vas_tv: RegExp("minside/tv/tilleggstjenester"),
  menu_link_customer_vas_internet: RegExp("minside/internett/tilleggstjenester"),
};

type Options = {
  fixedInvoiceLink?: string;
  isNewInvoice?: boolean;
  showNewProductPage?: boolean;
};

export function getMenuLinks(minSide: MinSide, { fixedInvoiceLink, isNewInvoice, showNewProductPage }: Options = {}) {
  const fakturaMenuItem: MenuLink | MenuLinkGroup = isNewInvoice
    ? {
        type: "MenuLink",
        isInternalLink: minSide === "fixed",
        icon: "document",
        label: "Faktura",
        id: "menu_link_invoice_fixed",
        href: uri.minSideFixed("/faktura"),
        limitedAccessView: true,
      }
    : {
        type: "MenuLinkGroup",
        icon: "document",
        label: "Faktura",
        id: "menu_link_invoice",
        items: [
          {
            type: "MenuLink",
            href: uri.minSideMobile("/faktura"),
            isInternalLink: minSide === "mobile",
            label: "Mobil",
            id: "menu_link_invoice_mobile",
            limitedAccessView: true,
          },
          {
            type: "MenuLink",
            href: fixedInvoiceLink ?? uri.minSideFixed("/faktura"),
            isInternalLink: fixedInvoiceLink ? false : minSide === "fixed",
            label: "TV/Internett",
            id: "menu_link_invoice_fixed",
            limitedAccessView: true,
          },
        ],
      };

  const menuLinks: MenuLinks = [
    {
      id: "menu_link_home",
      href: uri.minSideFixed("/hjem"),
      type: "MenuLink",
      isInternalLink: minSide === "fixed",
      icon: "home",
      label: "Hjem",
    },
    {
      type: "MenuLink",
      href: uri.minSideMobile(showNewProductPage ? "" : "/forbruk"),
      isInternalLink: minSide === "fixed",
      icon: "mobile",
      label: "Mobil",
      id: "menu_link_mobile",
      limitedAccessView: true,
    },
    {
      type: "MenuLink",
      href: showNewProductPage ? uri.minSideFixed("/tv") : uri.minSideFixed("/tv/tv-innhold"),
      isInternalLink: minSide === "fixed",
      icon: "tv-simple",
      label: "TV",
      id: "menu_link_tv",
      limitedAccessView: true,
    },
    {
      type: "MenuLink",
      href: uri.minSideFixed("/internett"),
      isInternalLink: minSide === "fixed",
      icon: "router",
      label: "Internett",
      id: "menu_link_internet",
      limitedAccessView: true,
    },
    fakturaMenuItem,
    {
      type: "MenuLinkGroup",
      icon: "grid-view",
      label: "Tjenester",
      id: "menu_link_customer_vas",
      items: [
        {
          isInternalLink: minSide === "fixed",
          type: "MenuLink",
          href: uri.minSideFixed("/tjenester"),
          label: "Mobil",
          id: "menu_link_customer_vas_mobile",
          limitedAccessView: true,
        },
        {
          isInternalLink: minSide === "fixed",
          type: "MenuLink",
          href: uri.minSideFixed("/internett/tilleggstjenester"),
          label: "Internett",
          id: "menu_link_customer_vas_internet",
          limitedAccessView: true,
        },
      ],
    },
    {
      type: "MenuLink",
      href: uri.minSideFixed("/personvern"),
      icon: "security",
      label: "Personvern",
      id: "menu_link_personvern",
      isInternalLink: minSide === "fixed",
    },
    {
      icon: "alarm",
      label: "Varsler",
      type: "MenuLink",
      href: uri.minSideFixed("/varsler"),
      isInternalLink: minSide === "fixed",
      id: "menu_link_alerts_fixed",
      limitedAccessView: true,
    },

    {
      type: "MenuLinkGroup",
      icon: "shopping",
      label: "Nettbutikk",
      id: "menu_link_shopping",
      items: [
        {
          type: "MenuLink",
          href: uri.openPages("/mobiltelefoner"),
          isInternalLink: false,
          label: "Mobiltelefoner",
          id: "menu_link_shopping_mobile",
        },
        {
          type: "MenuLink",
          href: uri.openPages("/smartklokker"),
          isInternalLink: false,
          label: "Smartklokker",
          id: "menu_link_shopping_smart-watch",
        },
        {
          type: "MenuLink",
          href: uri.openPages("/nettbrett"),
          isInternalLink: false,
          label: "Nettbrett",
          id: "menu_link_shopping_tablet",
        },
        {
          type: "MenuLink",
          href: uri.openPages("/mobilabonnement/pakketilbud/"),
          isInternalLink: false,
          label: "Pakketilbud",
          id: "menu_link_shopping_pakke_tilbud",
        },
        {
          type: "MenuLink",
          href: uri.openPages("/mobiltilbehor"),
          isInternalLink: false,
          label: "Tilbehør",
          id: "menu_link_shopping_addon",
        },
      ],
    },
    {
      type: "MenuLink",
      href: uri.minSideFixed("/hjelp"),
      isInternalLink: minSide === "fixed",
      icon: "support",
      label: "Hjelp",
      id: "menu_link_customer_care",
    },
  ];

  return menuLinks;
}

export function getCustomerLinks(minSide: MinSide) {
  const customerLinks: Omit<MenuLink, "type">[] = [
    {
      id: "menu_link_home",
      href: uri.minSideFixed("/hjem"),
      isInternalLink: minSide === "fixed",
      icon: "home",
      label: "Hjem",
    },
    {
      href: uri.minSideFixed("/profil"),
      icon: "end-user",
      label: "Profil",
      id: "menu_link_profile",
      isInternalLink: minSide === "fixed",
      limitedAccessView: true,
    },
    {
      isInternalLink: minSide === "fixed",
      icon: "invoice",
      label: "Faktura",
      id: "menu_link_invoice_fixed",
      href: uri.minSideFixed("/faktura"),
      limitedAccessView: true,
    },
    {
      href: uri.minSideFixed("/personvern"),
      icon: "security",
      label: "Personvern",
      id: "menu_link_personvern",
      isInternalLink: minSide === "fixed",
    },
  ];

  return customerLinks;
}
