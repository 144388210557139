import { types, uri } from "@telia-no-min-side/utils";
import { NotificationData } from ".";

function getStatusMessageUrl(url: string, statusMessage: types.fixed.StatusMessage) {
  const { addReturnUrl, isInternalLink } = statusMessage;

  const urlWithReturnUrl = (url: string) => {
    if (url.includes("?")) {
      return `${url}&returnUrl=${encodeURI(window.location.href)}`;
    }
    return `${url}?returnUrl=${encodeURI(window.location.href)}`;
  };
  const internalUrl = uri.minSideFixed(url);

  if (!isInternalLink && addReturnUrl) return urlWithReturnUrl(url);
  if (isInternalLink && !addReturnUrl) return internalUrl;
  if (isInternalLink && addReturnUrl) return urlWithReturnUrl(internalUrl);
  return url;
}

function getStatusMessageLink(statusMessage: types.fixed.StatusMessage): NotificationData["link"] {
  if (!statusMessage.url) return;

  return {
    url: getStatusMessageUrl(statusMessage.url, statusMessage),
    isInternalLink: statusMessage.isInternalLink ?? false,
    text: statusMessage.linkText ?? "Les mer",
  };
}

export function getNotificationFromStatusMessages(statusMessages?: types.fixed.StatusMessages): NotificationData[] {
  if (!statusMessages) return [];
  return statusMessages.map((statusMessage) => {
    return {
      type: statusMessage.type,
      title: statusMessage.title,
      message: statusMessage.message,
      isInternalLink: statusMessage.isInternalLink,
      link: getStatusMessageLink(statusMessage),
    };
  });
}
