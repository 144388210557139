import { Icon, Text, Flex } from "@telia/teddy";
import { types } from "@telia-no-min-side/utils";
import { ExpandableProductCard } from "components/expandable-product-card";
import { getPriceText } from "util/priceFormatter";

type Props = {
  subscriptionProduct?: types.fixed.Product;
  isSafeOnNetBenefitVisible?: boolean;
  isLoading: boolean;
};

export function SubscriptionInfoCard({ subscriptionProduct, isSafeOnNetBenefitVisible, isLoading }: Props) {
  return (
    <ExpandableProductCard
      category={subscriptionProduct?.title || "Bredbånd"}
      name={subscriptionProduct?.title2 || ""}
      price={getPriceText(subscriptionProduct?.price)}
      actions={subscriptionProduct?.actions}
      expandableContent={
        <>
          <Text as="p" variant="paragraph-100-bold">
            Inkludert
          </Text>
          {subscriptionProduct?.description && (
            <Flex mt="200" gap="200">
              <Icon name="speedometer" size="sm" />
              <Text variant="paragraph-100">{subscriptionProduct?.description}</Text>
            </Flex>
          )}
          {isSafeOnNetBenefitVisible && (
            <Flex mt="200" gap="200">
              <Icon name="secured-1" size="sm" />
              <Text variant="paragraph-100">Trygg på Nett-pakken - digital sikkerhet for hele familien</Text>
            </Flex>
          )}
          {subscriptionProduct?.speed?.down && (
            <Flex mt="200" gap="200">
              <Icon name="arrow-down" size="sm" />
              <Text variant="paragraph-100">Nedlastningshastighet: {subscriptionProduct.speed.down} Mbps</Text>
            </Flex>
          )}
          {subscriptionProduct?.speed?.up && (
            <Flex mt="200" gap="200">
              <Icon name="arrow-up" size="sm" />
              <Text variant="paragraph-100">Opplastningshastighet: {subscriptionProduct.speed.up} Mbps</Text>
            </Flex>
          )}
        </>
      }
      isLoading={isLoading}
    />
  );
}
