import { ChangeEvent, useEffect, useState } from "react";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { useSelectAccount } from "src/hooks/useSelectAccount";
import { useNavigate } from "react-router-dom";
import { Button, Container, Heading, Paragraph } from "@telia-no-min-side/components";
import { Select } from "./invoice-change-due-date/Select";
import { InfoAccordion } from "./invoice-change-due-date/InfoAccordion";
import { ChangeInfoCard } from "./invoice-change-due-date/ChangeInfoCard";
import { DueDateResult } from "./invoice-change-due-date/DueDateResult";
import { BillCycleInfo } from "src/gql/graphql";
import { CHANGE_BILL_CYCLE } from "./mutations";
import { GET_BILLING_CYCLES } from "./queries";
import { SkeletonForm } from "pages/profile/common/SkeletonForm";

// NOTE: Disabling this feature with NOCT-38167 until further notice
export function InvoiceChangeDueDatePage() {
  const [selectedOption, setSelectedOption] = useState<BillCycleInfo>();
  const navigate = useNavigate();
  const { selectedAccountId } = useSelectAccount();

  const { data, networkStatus, refetch } = useQuery(GET_BILLING_CYCLES, {
    variables: { accountId: selectedAccountId! },
    notifyOnNetworkStatusChange: true,
  });
  const [changeDueDate, { loading: changeLoading, data: changeData, error: changeError, reset }] =
    useMutation(CHANGE_BILL_CYCLE);

  useEffect(() => {
    // NOTE: Disabling this feature with NOCT-38167 until further notice
    // if (!selectedAccountId || data?.account.billingCycles?.orderedBillCycle) {
    //   navigate("/minside/faktura");
    // }
    navigate("/minside/faktura");
  }, []);

  const handleReset = () => {
    refetch();
    reset();
  };

  if (networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.loading || changeLoading) {
    return (
      <Container justifyContent="center" alignItems="center">
        <SkeletonForm />
      </Container>
    );
  }

  if (changeError || networkStatus === NetworkStatus.error) {
    return (
      <DueDateResult
        reset={handleReset}
        isError
        title="Beklager"
        paragraphs={[
          "Det har dessverre oppstått en ukjent feil. Vennligst prøv igjen, eller kontakt kundeservice hvis problemet gjentar seg.",
        ]}
      />
    );
  }

  if (changeData) {
    return (
      <DueDateResult
        title="Din forfallsdato er endret!"
        paragraphs={[
          "Dine endringer trer i kraft fra neste faktura.",
          "Vær oppmerksom på at din neste faktura kan bli annerledes, grunnet at antall dager kan bli justert.",
          "Hvis du vil endre forfallsdato igjen, må dette skje i neste fakturaperiode.",
        ]}
      />
    );
  }

  const billingCycles = data?.account.billingCycles;
  const dueDay = billingCycles?.currentBillCycle.dueDay;
  const startDay = billingCycles?.currentBillCycle.startDay;

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(billingCycles?.possibleBillCycles?.find((date) => e.target.value == String(date.billCycle)));
  };

  return (
    <Container showGoBackButton padding flexDirection="column" gap="lg" maxWidth="xs">
      <Heading tag="h1" variant="title-300">
        Bytt forfallsdato
      </Heading>

      <Container flexDirection="column">
        <Paragraph>Endres fra</Paragraph>

        <InfoAccordion startDate={startDay} dueDate={dueDay} />
      </Container>

      <Container flexDirection="column">
        <Select
          options={billingCycles?.possibleBillCycles}
          selectedValue={selectedOption?.billCycle || -1}
          onSelect={handleSelect}
        />

        <Paragraph isFaded>
          Vær oppmerksom på at datoen for periodestart også blir forandret hvis du velger å bytte forfallsdato.{" "}
        </Paragraph>
      </Container>

      {selectedOption && (
        <>
          <Paragraph removeMargin>Endres til</Paragraph>

          <ChangeInfoCard title="Forfallsdato" currentDate={dueDay} newDate={selectedOption.dueDay} />

          <ChangeInfoCard title="Periodestart" currentDate={startDay} newDate={selectedOption.startDay} />
        </>
      )}

      <Container flexDirection="row" justifyContent="end" alignItems="center" gap="lg" padding="vertical">
        <Button variant="text-purple" onClick={() => navigate(-1)}>
          Avbryt
        </Button>

        <Button
          disabled={!selectedOption}
          onClick={() => {
            selectedAccountId &&
              changeDueDate({
                variables: { accountId: selectedAccountId, billCycleCode: selectedOption?.billCycle || -1 },
              });
          }}
        >
          Bytt forfallsdato
        </Button>
      </Container>
    </Container>
  );
}
