import { Link } from "react-router-dom";
import { Grid, Flex, Heading, Card, Text, Icon, IconName } from "@telia/teddy";
import { config } from "util/config";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { useMobileDashboardContext } from "../../../../context/MobileDashboardContext";

type DataType = { condition: boolean; link: string; icon: IconName; title: string; description: string };

export function AdminSection() {
  const { isPrepaid } = useMobileDashboardContext();
  const data: DataType[] = [
    {
      condition: true,
      link: INTERNAL_LINK.MOBILE_NEW.SIM_PIN_PUK_PAGE,
      icon: "simcard",
      title: "SIM, PIN & PUK",
      description: "Administrer, bestill, sperr og aktiver dine SIM-kort",
    },
    {
      condition: true,
      link: INTERNAL_LINK.MOBILE_NEW.SETTINGS_PAGE,
      icon: "settings",
      title: "Innstillinger",
      description: "Administrer dine innstillinger",
    },
    {
      condition: isPrepaid && config.showNewPrepaidPage,
      link: INTERNAL_LINK.MOBILE_NEW.PREPAID_ORDER_HISTORY,
      icon: "summary",
      title: "Kjøpshistorikk",
      description: "Se oversikt over dine kjøp",
    },
    // {
    //   condition: true,
    //   link: INTERNAL_LINK.MOBILE_NEW.PROFILE_PAGE,
    //   icon: "address-book",
    //   title: "Kontaktinformasjon",
    //   description: "Se og endre din kontaktinformasjon",
    // },
  ];

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Administrer
      </Heading>
      <Grid columns={{ md: "1", lg: "2" }} justify="stretch" gap="gutter-xl">
        {data.map(
          (item) =>
            item.condition && (
              <Card key={item.title} layout="navigation-horizontal-small" variant="gray" width="100%">
                <Card.Illustration>
                  <Icon name={item.icon} size="lg" />
                </Card.Illustration>
                <Card.Heading variant="title-100">{item.title}</Card.Heading>
                <Card.Content>
                  <Text>{item.description}</Text>
                </Card.Content>
                <Card.Button aria-label="Proceed to page" asChild iconOnly>
                  <Link to={item.link}>
                    <Icon name="arrow-right" />
                  </Link>
                </Card.Button>
              </Card>
            )
        )}
      </Grid>
    </Flex>
  );
}
