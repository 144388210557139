import { types } from "@telia-no-min-side/utils";
import { NotificationData } from ".";

function hasUnlinkedModem(linkingDevices?: types.fixed.LinkingDevices) {
  return linkingDevices?.some((modem) => modem.isNewDevice && modem.linked === false);
}

export function getNotificationUnlinkedModem(linkingDevices?: types.fixed.LinkingDevices): NotificationData[] {
  return hasUnlinkedModem(linkingDevices)
    ? [
        {
          type: "unlinked-modem",
          title: "Du har et nytt modem som ikke er aktivert.",
          message:
            "Modemet må aktiveres, og da vil det gamle slutte å fungere. Det nye modemet må kobles opp for at du skal komme på nett igjen.",
        },
      ]
    : [];
}
