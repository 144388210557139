import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, useWindowSize } from "@telia-no-min-side/components";
import { TabLink } from "../style";

type Props = {
  tabs: { tabName: string; link: string }[];
  ignorePath?: boolean;
};

export function DashboardTabs({ tabs, ignorePath }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (ignorePath) return;

    const isTabInPath = tabs.some((tab) => location.pathname.includes(tab.link));

    if (!isTabInPath && tabs[0]) {
      return navigate(tabs[0].link);
    }
  }, [tabs, ignorePath]);

  return (
    <Container
      padding="horizontal"
      flexDirection="row"
      justifyContent={isMobile ? "space-between" : "start"}
      alignItems="center"
      gap="lg"
      maxWidth="full"
      style={{ boxShadow: "3px 8px 8px 0px rgba(0, 0, 0, 0.16)" }}
      data-tracking-id="mobile-dashboard-tabs-layout"
    >
      {tabs.map((tab) => (
        <TabLink key={tab.tabName} to={tab.link}>
          {tab.tabName}
        </TabLink>
      ))}
    </Container>
  );
}
