import React from "react";
import { Heading } from "@telia/styleguide";
import { v4 as uuid } from "uuid";
import { NotificationBox, NotificationBoxProps, Link } from "@telia-no-min-side/components";
import { useFetchNotification, NotificationData } from "src/api/fixed/useFetchNotification";
import { Centering } from "../../styles/page-styles";
import { NotificationHeading, StyledContainer } from "./style";

function getNotificationKind(type: NotificationData["type"]): NotificationBoxProps["kind"] {
  if (["operational-status", "operational-messages", "broadband-subscription-abused"].includes(type)) {
    return "error";
  }
  return "warning";
}

export function Notification(): JSX.Element {
  const { data, isLoading } = useFetchNotification();

  return (
    <StyledContainer maxWidth="md" horizontalPosition={data.length || isLoading ? "left" : "center"}>
      {(!!data.length || isLoading) && <Heading text="Dine varsler for TV og internett" tag="h1" size="s" />}

      {!data.length && !isLoading && (
        <Centering>
          <p>Du har ingen varsler.</p>
        </Centering>
      )}

      {data?.map((alertMessage) => (
        <NotificationBox key={uuid()} kind={getNotificationKind(alertMessage.type)} showCloseButton={false}>
          <NotificationHeading>{alertMessage.title}</NotificationHeading>
          {alertMessage.message}
          {alertMessage.link && (
            <>
              {" "}
              <Link href={alertMessage.link.url} isInternalNavigation={alertMessage.link.isInternalLink}>
                {alertMessage.link.text}
              </Link>
            </>
          )}
        </NotificationBox>
      ))}
    </StyledContainer>
  );
}
