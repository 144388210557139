import { Container, TopSection } from "@telia-no-min-side/components";
import { MoveOutRegistration } from "./components/MoveOutRegistration";
import { FixedProfilePicker } from "src/components/profile-pickers/FixedProfilePicker";
import { config } from "util/config";
import { TvTopMenu } from "components/top-menu/TvTopMenu";

export function MoveOutPage() {
  return (
    <Container flexDirection="column" gap maxWidth="full">
      {config.isNewProductPageEnabled ? (
        <TvTopMenu dataTrackingID="moveout-fixed-profile-picker" />
      ) : (
        <TopSection>
          <FixedProfilePicker dataTrackingID="moveout-fixed-profile-picker" />
        </TopSection>
      )}
      <Container flexDirection="column" gap padding maxWidth="xs">
        <MoveOutRegistration />
      </Container>
    </Container>
  );
}
