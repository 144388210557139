import { Breadcrumb } from "components/Breadcrumb";
import { Navigation } from "./Navigation";
import { Flex } from "@telia/teddy";
import { SubscriptionCardMBB } from "./components/SubscriptionCardMBB";
import { OrderSection } from "./components/OrderSection";
import { MBBDashboardProvider } from "../hooks/useMBBSubscriptionData";
import { MBBDrawer } from "./components/MBBDrawer";
import { UsageAndAdminSection } from "./components/UsageAndAdminSection";
import { TopupModal } from "./components/TopupModal";

export function MBBDashboard() {
  return (
    <MBBDashboardProvider>
      <MBBDrawer />
      <TopupModal />

      <Flex direction="column" gap="50px" maxWidth="720px" pb="300">
        <Flex direction="column" gap="gutter-xl">
          <Breadcrumb />
          <SubscriptionCardMBB />
          <Navigation />
        </Flex>

        <UsageAndAdminSection />

        <OrderSection />
      </Flex>
    </MBBDashboardProvider>
  );
}
