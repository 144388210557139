import dayjs from "dayjs";
import { types } from "@telia-no-min-side/utils";
import { NotificationData } from ".";

function getTitle(name: string, begin: string) {
  const formattedDate = dayjs(begin).format("DD.MM.YYYY kl HH:mm");
  return formattedDate ? `(${formattedDate}) ${name}` : name;
}

export function getNotificationFromOperationalMessages(
  operationalMessages?: types.fixed.OperationalMessages
): NotificationData[] {
  if (!operationalMessages?.outages) return [];
  return operationalMessages?.outages.map((operationalMessage) => ({
    type: "operational-messages",
    title: getTitle(operationalMessage.name, operationalMessage.begin),
    message: operationalMessage.description,
  }));
}
