import type { BaseSyntheticEvent } from "react";
import { Container } from "@telia-no-min-side/components";
import { useFlexOrder } from "../context/FlexOrderProvider";
import { ProductSelector } from "../product-selector/ProductSelector";
import { RequirementsSelector } from "../requirement-selector/RequirementsSelector";
import { Receipt } from "./Receipt";

export function PageHandler() {
  const {
    receiptRef,
    pageView,
    setPageView,
    setRequirementIndex,
    hasPreviousRequirementsPage,
    pageRequirement,
    submitFlexData,
    flexRequirementsData,
    resetFlexProductAndStartStep1,
  } = useFlexOrder();

  const handleGoBackClick = (e: BaseSyntheticEvent) => {
    if (pageView === "REQUIREMENT_SELECT") {
      e.preventDefault();
      if (hasPreviousRequirementsPage) {
        return setRequirementIndex((prev) => prev - 1);
      }
      return resetFlexProductAndStartStep1();
    }

    if (pageView === "RECEIPT" && submitFlexData.receipt?.showCustomerService) {
      e.preventDefault();
      if (flexRequirementsData?.length) {
        return setPageView("REQUIREMENT_SELECT");
      }
      return setPageView("PRODUCT_SELECT");
    }
  };

  return (
    <Container padding showGoBackButton onGoBackClick={handleGoBackClick}>
      {pageView === "PRODUCT_SELECT" && <ProductSelector />}
      {pageView === "REQUIREMENT_SELECT" && pageRequirement && <RequirementsSelector />}
      <div ref={receiptRef}>{pageView === "RECEIPT" && <Receipt receipt={submitFlexData.receipt} />}</div>
    </Container>
  );
}
