import { isPricePlanMobileBroadband } from "util/format-subscription/isPricePlanMobileBroadband";
import { Profiles, useProfilePicker } from "components/profile-pickers/hooks//useProfilePicker";

export function useBroadbandProfiles() {
  const { mobile, fixed } = useProfilePicker();

  const loading = mobile.loading || fixed.loading;

  const mobileBroadbandProfiles = mobile.profiles.filter((profile) =>
    isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan)
  );

  const profiles = [...fixed.profiles, ...mobileBroadbandProfiles] as Profiles;

  return { profiles, loading };
}
