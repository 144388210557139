import { graphql } from "src/gql";

// export const GET_MBB_SUBSCRIPTION_DATA = graphql(`
//   query getMBBSubscriptionData($phoneNumber: String!) {
//     subscription(phoneNumber: $phoneNumber) {
//       phoneNumber {
//         localNumber
//       }
//       roles
//       agreementType
//       subscriptionRank
//
//       userOffering {
//         longDescription
//         disclaimers {
//           code
//           name
//           text
//         }
//         pricePlan
//         shortName
//         monthlyCost {
//           amount
//         }
//         originalPrice {
//           amount
//         }
//         additionalProperties {
//           name
//           value
//         }
//         offeringPrices {
//           offeringPrices {
//             code
//             amount
//             monetaryUnit
//           }
//         }
//       }
//
//       additionalProducts {
//         id
//         code
//         name
//         productCategory
//         productTerms {
//           code
//           amount
//           monetaryUnit
//         }
//       }
//     }
//   }
// `);

export const GET_MBB_SUBSCRIPTION_DATA = graphql(`
  query getMBBSubscriptionData($phoneNumber: String!) {
    subscription(phoneNumber: $phoneNumber) {
      phoneNumber {
        localNumber
      }
      roles
      agreementType
      billingType
      userOffering {
        longDescription
        disclaimers {
          code
          name
          text
        }

        pricePlan
        shortName
        monthlyCost {
          amount
        }
        originalPrice {
          amount
        }

        additionalProperties {
          name
          value
        }
        offeringPrices {
          offeringPrices {
            code
            amount
            monetaryUnit
          }
        }
      }
      additionalProducts {
        id
        code
        name
        productCategory
        presentation {
          active
          modifiable
        }
        productTerms {
          code
          amount
          monetaryUnit
        }
      }
      usage {
        #   unit
        expireDate
        #   allowanceType
        type
        usage
        service
        category
        activeDataBoostSession
        #   quotaId
        #   network

        remaining {
          amount
          unit
          # customQuantity
          # formatted
        }
        #   used {
        #     amount
        #     unit
        #     customQuantity
        #     formatted
        #   }
        limit {
          amount
          unit
          # customQuantity
          # formatted
        }
        #   description
        period {
          # startDate
          endDate
        }
        #   dataShaping {
        #     balance {
        #       amount
        #       unit
        #       customQuantity
        #       formatted
        #     }
        #     limit {
        #       amount
        #       unit
        #       customQuantity
        #       formatted
        #     }
        #   }
      }
      # dataSharingQualification {
      #   eligibleForDataSharing
      #   nonEligibilityReasons {
      #     code
      #     label
      #   }
      # }
    }
  }
`);
