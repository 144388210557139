import { ExpandableCard, Heading, Skeleton, Text } from "@telia/teddy";
import { ErrorBox } from "components/ErrorBox";
import { useMBBSubscriptionData } from "pages/broadbandNew/hooks/useMBBSubscriptionData";
import { getSimplePriceText } from "util/priceFormatter";
import { CardContentMBB, CardExpandedContentMBB } from "./CardContentMBB";
import { PrepaidSubscriptionInfo } from "pages/prepaid/subscription-info";

export function SubscriptionCardMBB() {
  const {
    loading,
    error,
    refetch,
    subHeading,
    subscriptionName,
    originalMonthlyPrice,
    monthlyPrice,
    includedProperties,
    changeSubscriptionLink,
    isPrepaid,
  } = useMBBSubscriptionData();

  if (loading) return <Skeleton height="180px" width="100%" maxWidth="680px" />;
  if (error)
    return (
      <ErrorBox
        title="Vi beklager"
        description="Dessverre oppstod det en feil under innlastningen av denne siden. Vennligst prøv igjen litt senere."
        actionContent="Last side på nytt"
        onClick={() => refetch()}
        maxWidth="680px"
      />
    );

  if (isPrepaid && subscriptionName) {
    return <PrepaidSubscriptionInfo subscriptionName={subscriptionName} />;
  }

  return (
    <ExpandableCard type="multiple">
      <ExpandableCard.Item value="temp1">
        <ExpandableCard.Trigger>
          {subHeading && (
            <Text as="p" mb="25" variant="paragraph-100">
              {subHeading}
            </Text>
          )}

          <ExpandableCard.Header>
            <ExpandableCard.Description>
              <Heading as="h4" variant="title-100">
                {subscriptionName}
              </Heading>
              <ExpandableCard.Price>
                {typeof originalMonthlyPrice === "number" && originalMonthlyPrice > (monthlyPrice || 0) && (
                  <Text as="p" mr="100" variant="paragraph-100">
                    <s>{getSimplePriceText(originalMonthlyPrice)}</s>
                  </Text>
                )}
                <Heading as="h4" variant="title-100">
                  {getSimplePriceText(monthlyPrice)}
                </Heading>
                <Text as="p" variant="paragraph-100">
                  /md.
                </Text>
              </ExpandableCard.Price>
            </ExpandableCard.Description>
            <ExpandableCard.Indicator />
          </ExpandableCard.Header>

          <ExpandableCard.Line />
          <ExpandableCard.HiddenContainer>
            <CardContentMBB includedProperties={includedProperties} changeSubscriptionLink={changeSubscriptionLink} />
          </ExpandableCard.HiddenContainer>
        </ExpandableCard.Trigger>

        <CardExpandedContentMBB
          includedProperties={includedProperties}
          changeSubscriptionLink={changeSubscriptionLink}
        />
      </ExpandableCard.Item>
    </ExpandableCard>
  );
}
