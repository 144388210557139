import { useSelectAccount } from "hooks/useSelectAccount";
import { useFetchNetworkStatistics } from "src/api/fixed";
import { useAuth } from "@telia-no-min-side/components";
import { AUTH } from "util/constants/auth";
import { NavigationButtons, type Props as NavigationButtonsProps } from "../../../components/NavigationButtons";
import { INTERNAL_LINK } from "util/constants/internalLinks";
import { useMBBSubscriptionData } from "../hooks/useMBBSubscriptionData";

export function Navigation() {
  const { selectedAccountType } = useSelectAccount();
  const { networkStatistics, isValidating } = useFetchNetworkStatistics();
  const { selectedAuthProvider } = useAuth();
  const { isPrepaid } = useMBBSubscriptionData();

  const isFixedAccount = selectedAuthProvider !== AUTH.PROVIDER.CT && selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED;
  const isMBBAccount = selectedAccountType === AUTH.ACCOUNT_TYPE.MOBILE;

  const fixedLinks: NavigationButtonsProps["buttonsData"] = [
    { URL: INTERNAL_LINK.INVOICE_PAGE, text: "Faktura", icon: "invoice" },
    { URL: INTERNAL_LINK.FIXED.BROADBAND_SERVICES_PAGE, text: "Tjenester", icon: "services" },
    {
      URL: INTERNAL_LINK.FIXED.WIFI_NETWORK_SETTINGS_PAGE,
      text: "Innstillinger",
      icon: "settings",
      hide: !networkStatistics?.success,
      isLoading: isValidating,
    },
  ];

  const mbbLinks: NavigationButtonsProps["buttonsData"] = [
    { URL: INTERNAL_LINK.INVOICE_PAGE, text: "Faktura", icon: "invoice" },
    { URL: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_SETTINGS_PAGE, text: "Innstillinger", icon: "settings" },
    { URL: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_SIM_CARD_PAGE, text: "SIM, PIN & PUK", icon: "simcard" },
  ];

  const mbbPrepaidLinks: NavigationButtonsProps["buttonsData"] = [
    { URL: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_SIM_CARD_PAGE, text: "SIM, PIN & PUK", icon: "simcard" },
    { URL: INTERNAL_LINK.FIXED.MOBILE_BROADBAND_SETTINGS_PAGE, text: "Innstillinger", icon: "settings" },
  ];

  return (
    <NavigationButtons
      buttonsData={isFixedAccount ? fixedLinks : isMBBAccount ? mbbLinks : isPrepaid ? mbbPrepaidLinks : []}
    />
  );
}
