import React, { ComponentProps } from "react";
import { isPricePlanMobileBroadband } from "src/util/format-subscription/isPricePlanMobileBroadband";
import { ProfileValue, Profiles, Subscription, isMobileCustomer, useProfilePicker } from "./hooks/useProfilePicker";
import { ProfilePickerTypes, ProfilePicker, Container, Icon } from "@telia-no-min-side/components";
import { UserRole } from "src/gql/graphql";
import { useNavigate } from "react-router-dom";
import { NoSubscriptionsFound } from "components/profile-pickers/components/NoSubscriptionsFound";

/**
 * MobileSubscriptionPicker is used for switching between mobile phone
 * subscriptions only.
 */

type Props = {
  subcriptionType?: "all" | "mbb" | "mobile";
  selectedProfileId?: string;
  onProfileSelect?: (id: string, profile: Subscription) => void;
  variant?: ComponentProps<typeof ProfilePicker>["variant"];
  hideFamilyTab?: boolean;
  /* pass additional components like <BuyNewSubscriptionCTA /> */
  children?: React.ReactNode;
};

export function MobileSubscriptionPicker({
  subcriptionType = "mobile",
  selectedProfileId,
  onProfileSelect,
  variant = "profile-picker",
  hideFamilyTab = false,
  children,
}: Props) {
  const navigate = useNavigate();
  const { mobile } = useProfilePicker({ filter: "mobile" });

  const getSubscriptions = () => {
    if (subcriptionType === "mbb") {
      return mobile.profiles.filter((profile) => isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan));
    }
    if (subcriptionType === "mobile") {
      return mobile.profiles.filter((profile) => !isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan));
    }
    return mobile.profiles;
  };

  const subscriptions = getSubscriptions();

  const isLegalOwnerOfAnyAccount = subscriptions.some((profile) =>
    profile.value.account.roles.includes(UserRole.LegalOwner)
  );
  const showFamilyTab = !hideFamilyTab && isLegalOwnerOfAnyAccount && subscriptions.length > 1;

  const showRetry = !mobile.loading && mobile.hasError;

  const onRetry = () => {
    if (mobile.hasError) {
      mobile.refetch();
    }
  };

  const onProfileChange = (value: ProfileValue) => {
    if (onProfileSelect && isMobileCustomer(value)) {
      onProfileSelect(value.phoneNumber.localNumber, value);
    }
  };

  const familyTab: ProfilePickerTypes.Profile<string>[] = showFamilyTab
    ? [
        {
          id: "familie",
          value: "familie",
          title: (
            <Container flexDirection="row" alignItems="center" gap>
              <Icon icon="group" size="sm" />
              Familie
            </Container>
          ),
          onClick: (e) => {
            e.preventDefault();
            if (selectedProfileId === "familie") return;
            navigate("mine-abonnement");
          },
        },
      ]
    : [];

  const profiles = [...subscriptions, ...familyTab] as Profiles;

  const selectedProfile = profiles.find((profile) => profile.id === selectedProfileId)?.value;

  const showLoading = !subscriptions?.length && mobile.loading;

  return (
    <ProfilePicker
      profiles={profiles}
      selectedProfile={selectedProfile}
      loading={showLoading}
      onChange={onProfileChange}
      showRetry={showRetry}
      onRetry={onRetry}
      noProfilesPlaceholder={<NoSubscriptionsFound isMobile />}
      variant={variant}
    >
      {children}
    </ProfilePicker>
  );
}
