import { Button, Modal } from "@telia-no-min-side/components";
import styled from "styled-components";

// BuyNewSubscriptionCTA styles

export const SelectSubCard = styled(Modal)`
  position: fixed;
  transform: translate(0, 0);
  width: unset;
  padding: 1.5rem 1.5rem 1rem 1rem;
  overflow: visible;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;
